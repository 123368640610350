// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function ($rootScope) {
    var data, profile;
    data = $rootScope.initialData;
    profile = {
      accountName: data.accountName,
      plan: data.plan,
      isEvangelist: data.evangelist === true || false,
      isPartner: data.isPartner === true || false,
      secret: data.secret,
      branding: data.branding,
      user: {
        email: data.userEmail,
        firstName: data.userFirstName,
        lastName: data.userLastName || "",
      },
    };
    return profile;
  };
}.call(this));
