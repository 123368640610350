// Generated by CoffeeScript 1.12.7
(function () {
  var FlexboxChecker, Injector, flexboxCheckerInstance;

  require("./hydrateComponents");

  Injector = require("hxroin");

  FlexboxChecker = require("./Common/FlexboxChecker")["default"];

  flexboxCheckerInstance = FlexboxChecker();

  flexboxCheckerInstance.handleNoFlexbox();

  require("./app.ng.html");

  angular
    .module("qwilr", [
      "ngSanitize",
      "ngAnimate",
      "duScroll",
      "create-ui",
      "mgcrea.ngStrap.tooltip",
      require("DomTrick/DomTrick").name,
      require("AspectResize/AspectResize").name,
      require("Common/RightClick").name,
      require("Quote/Directive").name,
      require("QuoteV2/Directive").name,
      require("VideoBackground/Directive").name,
      require("AcceptButtonv2/Directive").name,
      require("AcceptV2/Directive")["default"].name,
      require("SpringboardEngagement/Directive")["default"].name,
      require("CommonUI/Slider/Directive").name,
      require("CommonUI/Checkbox/Checkbox").name,
      require("Widgets/MountWidgetsDirective/MountWidgetsDirective")["default"].name,
    ])
    .config([
      "$locationProvider",
      function ($locationProvider) {
        return $locationProvider.html5Mode({
          enabled: true,
          requireBase: false,
        });
      },
    ])
    .run(function ($templateCache) {
      return (window.$templateCache = $templateCache);
    })
    .controller("QwilrPublicController", function ($scope, $rootScope, $injector, $timeout) {
      var bootstrapper, di;
      di = new Injector($injector);
      $scope.ui = {};
      return (bootstrapper = di.make(require("Common/Bootstrapper/Bootstrapper"), {
        scopeUi: $scope.ui,
      }));
    });
}.call(this));
