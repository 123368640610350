/**
 * Helper function for any additional dom modifications for elements that
 * have been split
 *
 * @param {jquery selector} frontElement the front of the element we just split
 * @param {jquery selector} backElement the back of the element we just split, to be modified
 * @param {boolean} childrenWereSplit whether the children nodes were split to get the frontElement
 * and backElement
 */
module.exports = function (frontElement, backElement, childrenWereSplit) {
  var tagName = backElement.get(0).tagName;

  if (tagName === "BLOCKQUOTE") {
    modifyBlockquote(backElement);
  } else if (tagName === "OL") {
    modifyOrderedList(frontElement, backElement, childrenWereSplit);
    if (childrenWereSplit) {
      modifySplitList(backElement);
    }
  } else if (tagName === "UL" && childrenWereSplit) {
    modifySplitList(backElement);
  } else if (tagName === "LI") {
    modifySplitListItem(frontElement, backElement);
  } else if (backElement.find("table").length > 0) {
    modifyTable(frontElement, backElement);
  }

  /**
   * With blockquotes, we remove the ::before quotation mark
   *
   * @param {jquery selector} backElement
   */
  function modifyBlockquote(backElement) {
    backElement.addClass("no-quote-mark");
  }

  /**
   * With ordered lists, we need to set the start number to continue
   * the numbering from the part we just split from
   */
  function modifyOrderedList(frontElement, backElement, childrenWereSplit) {
    // What was the starting number of the front part of the list?
    // If there isn't one, that means we started at 1
    var frontStart = parseInt(frontElement.attr("start")) || 1;

    // How long was the front part of the list?
    // Count all the LI elements that are direct children of the list
    var frontLength = frontElement.children("LI").length;

    var startNumber = frontStart + frontLength;

    // If we just split the page in the middle of a list item, then we start off the new page with
    // a `list-style: none` list item. We adjust our numbering to account for this
    if (childrenWereSplit) {
      --startNumber;
    }

    backElement.attr("start", startNumber);
  }

  function modifySplitList(backElement) {
    backElement.children("LI:first-child").css("list-style", "none");
  }

  function modifySplitListItem(frontElement, backElement) {
    const listItemIndex = frontElement.find("> .before");
    const isOrderedListItem = listItemIndex.length === 1;

    if (isOrderedListItem) {
      backElement.prepend(listItemIndex.clone());
    }

    backElement.addClass("no-list-item-icon");
  }

  /**
   * With tables, we need to copy the colgroup element
   * so the column width sizes match the original table
   */
  function modifyTable(frontElement, backElement) {
    var colgroup = frontElement.find("table colgroup").first();
    backElement.find("table").first().prepend(colgroup.clone());
  }
};
