// Generated by CoffeeScript 1.12.7

/*
  This module instantiates a Qwilr Quote block on a public page.

  It is responsible for configuring Pay Now (if enabled)
  and for re-rendering the quote block if the project is accepted.
 */

(function () {
  module.exports = function (di, createUi, make, recalculate, block, projectState, appEvents, mobileBreakpoint) {
    var acceptButton, base, config, display, hasAcceptButton, isAcceptable, makeDisplay, ref;
    recalculate();
    display = null;
    makeDisplay = function () {
      return (display = make(require("Quote/Display/Display.js"), {
        MOBILE_BREAKPOINT: mobileBreakpoint,
        projectState: projectState,
      }));
    };
    makeDisplay();
    appEvents.on("accepted", function () {
      return makeDisplay();
    });
    this.ui = createUi(require("Quote/Quote.html"), {
      sections: block.sections,
      display: display.ui,
      $init: function (scope) {
        return scope.$watch(
          "ui.sections",
          function (sections) {
            if (sections == null) {
              return;
            }
            return recalculate();
          },
          true,
        );
      },
    });
    hasAcceptButton = ((ref = block.settings.accept) != null ? ref.active : void 0) === true;
    isAcceptable = block.$projectStatus === "live" && hasAcceptButton;
    if (isAcceptable) {
      config = block.settings.accept;
      if (config.payNowEnabled != null && config.payNowEnabled === true) {
        if (config.payNow == null) {
          config.payNow = {};
        }
        if ((base = config.payNow).payPercent == null) {
          base.payPercent = 100;
        }
        config.payNow.currency = block.settings.currency;
        config.payNow.amount = function () {
          if (config.payNow.payPercentEnabled) {
            return block.totalWithTaxes * (config.payNow.payPercent / 100);
          } else {
            return block.totalWithTaxes;
          }
        };
      }
    } else {
      config = {};
    }
    acceptButton = make(require("./Button/Button"), {
      config: config,
      hasAcceptButton: hasAcceptButton,
      isAcceptable: isAcceptable,
    });
    return (this.ui.acceptButton = acceptButton.ui);
  };
}.call(this));
