"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPostAcceptanceRedirectUrl = exports.makeOnPostAcceptanceRedirect = void 0;
var makeOnPostAcceptanceRedirect = function (projectCreatorProfile, visitToken) { return function () {
    window.location.href = "/post-acceptance/".concat(projectCreatorProfile.secret, "/").concat(visitToken);
}; };
exports.makeOnPostAcceptanceRedirect = makeOnPostAcceptanceRedirect;
var getPostAcceptanceRedirectUrl = function (projectCreatorProfile, visitToken) {
    return "/post-acceptance/".concat(projectCreatorProfile.secret, "/").concat(visitToken);
};
exports.getPostAcceptanceRedirectUrl = getPostAcceptanceRedirectUrl;
