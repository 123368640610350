"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAgreementViewedEvent = void 0;
var Types_1 = require("../../Types");
var Types_2 = require("../Types");
var HashWidgetId_1 = require("../Utils/HashWidgetId");
var formatAgreementViewedEvent = function (_a) {
    var pageInfo = _a.pageInfo, blockId = _a.blockId, blockType = _a.blockType, blockName = _a.blockName;
    var interactionName = Types_2.PageInteractionName.AgreementViewed;
    var interactionContentIdentifier = "Agreement viewed";
    return {
        eventType: Types_1.EventType.PageInteraction,
        eventData: {
            blockId: blockId,
            viewId: pageInfo.pageViewId,
            pageId: pageInfo.pageId,
            accountId: pageInfo.accountId,
            fullName: pageInfo.fullName,
            viewUserId: pageInfo.viewUserId,
            interactionType: "content",
            interactionContentIdentifier: interactionContentIdentifier,
            widgetId: (0, HashWidgetId_1.hashWidgetId)({ interactionName: interactionName, blockId: blockId, interactionContentIdentifier: interactionContentIdentifier }),
            interactionMetadata: {
                interactionName: interactionName,
                blockType: blockType,
                blockName: blockName,
            },
        },
        timestamp: new Date().toISOString(),
    };
};
exports.formatAgreementViewedEvent = formatAgreementViewedEvent;
