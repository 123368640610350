// Generated by CoffeeScript 1.12.7

/*
  This is an Angular directive which creates a Quote.
  Its role is to make() the Quote UI, seeded with
  the initialisation data.
 */

(function () {
  var Quote, QuoteCurrencyFormatter;

  Quote = require("./QuoteV2");

  QuoteCurrencyFormatter = require("Utils/QuoteCurrencyFormatter")["default"];

  module.exports = angular.module("quoteV2", []).directive("qwilrQuotev2", function ($rootScope, $timeout) {
    return {
      restrict: "E",
      template: '<div ui="quoteV2.ui"></div>',
      scope: {
        initData: "@",
      },
      link: function (scope, elem, attrs) {
        var childScope, di, quoteCurrencyFormatter;
        di = $rootScope.di;
        childScope = di.child();
        childScope.load({
          block: JSON.parse(attrs.initData),
        });
        quoteCurrencyFormatter = childScope.make(QuoteCurrencyFormatter);
        childScope.load({
          quoteCurrencyFormatter: quoteCurrencyFormatter,
        });
        return (scope.quoteV2 = childScope.make(Quote));
      },
    };
  });
}.call(this));
