"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAcceptStartedEvent = void 0;
var Types_1 = require("../../Types");
var Types_2 = require("../Types");
var crypto_1 = require("crypto");
var formatAcceptStartedEvent = function (_a) {
    var pageInfo = _a.pageInfo, blockId = _a.blockId, formField = _a.formField, formValue = _a.formValue, blockType = _a.blockType, blockName = _a.blockName;
    return ({
        eventType: Types_1.EventType.PageInteraction,
        eventData: {
            accountId: pageInfo.accountId,
            blockId: blockId,
            fullName: pageInfo.fullName,
            interactionContentIdentifier: "Accept Form",
            interactionType: "pricingAndTransaction",
            interactionMetadata: {
                formField: formField,
                formValue: formValue,
                interactionName: Types_2.PageInteractionName.AcceptProcessStarted,
                blockType: blockType,
                blockName: blockName,
            },
            pageId: pageInfo.pageId,
            viewId: pageInfo.pageViewId,
            viewUserId: pageInfo.viewUserId,
            widgetId: (0, crypto_1.createHash)("md5").update("".concat(blockId, "#acceptform")).digest("hex"),
        },
        timestamp: new Date().toISOString(),
    });
};
exports.formatAcceptStartedEvent = formatAcceptStartedEvent;
