/**
 * Helper functions for splitting and rejoining the dom using qed.
 * You can think of sliceUpwards() and joinUpwards() as approximate inverses of each other
 *
 * @NOTE (som, 2017-08-21): while there are existing helper functions in
 * `/Client/Application/Project/QwilrEditor/Text/Helpers.js`, our use case when print
 * formatting for PDF is a bit different, so they were not directly adaptable
 */

module.exports = {
  sliceUpwards: sliceUpwards,
  joinUpwards: joinUpwards,
};

/**
 * Slice a dom up multiple levels
 *
 * @param {qed.Point} point the point to start splitting on. This object is modified to reflect
 * it's position in the modified split dom
 * @param {jquery selector} topAncestor stop splitting when we reach this element
 * @returns {integer} how many times joinLeft() needs to be called to repair this slice
 */
function sliceUpwards(point, topAncestor) {
  // Keep track of how many times we've split the dom
  var splitLevels = 0;

  // RightNormalize the point we are given so all the logic in here can assume we are working
  // with a rightNormalized point (since splitRight() always rightNormalizes)
  point.setTo(point.rightNormalized());

  // The dom doesn't change if we're splitting before a node and it is the first sibling.
  // splitRight() should never get us in this state, so we only need to check this with
  // the point we are given
  if (isFirstSibling(point.node) && point.type === qed.Point.types.BEFORE) {
    --splitLevels;
  }

  // We stop splitting when we're at the level below the top ancestor
  // Since it's possible for splitRight() to cause the point to skip a level,
  // we also need to check that the point itself is not on the top ancestor
  /**
   * @NOTE (som, 2017-08-21): this will fail if the point somehow jumps up multiple levels
   * of the dom and skips the topAncestor entirely, but I can't see anywhere in the qed
   * splitting code that can cause that to happen. If you see the <body> of the dom being
   * split, that means it HAS happened and we need a more robust way of checking if the point
   * is above the topAncestor
   */
  while (!topAncestor.is(point.node.parentNode) && !topAncestor.is(point.node)) {
    // If we're splitting at the end of a node, the point will simply slide to before
    // the next node without modifying the dom. This means we need 1 fewer joinLeft() to
    // repair this slice
    if (point.type === qed.Point.types.END) {
      --splitLevels;
    }

    // We avoid triggering a split if the point is before the first child of the parent node,
    // i.e. if there is no element before this element.
    if (isFirstSibling(point.node) && point.type === qed.Point.types.BEFORE) {
      point.setTo(qed.Point.before(point.node.parentNode));
    } else {
      point.splitRight();
      ++splitLevels;
    }
  }
  return splitLevels;
}

/**
 * Repair the effects of a sliceUpwards()
 *
 * The actual logic for how many times joinLeft() needs to be called is entirely in sliceUpwards(),
 * since it's impossible to infer the 'repaired' state of the dom after it has been split
 *
 * @param {qed.Point} point the point that was used for the sliceUpwards()
 * @param {integer} levels how many times we should try to join. Use the value returned by sliceUpwards()
 *
 */
function joinUpwards(point, levels) {
  for (var i = 0; i < levels; ++i) {
    point.joinLeft();
  }
}

/**
 * Is this node the first sibling that is an element or text node?
 * @param {node} rawNode
 * @returns {bool}
 */
function isFirstSibling(rawNode) {
  var prevSibling = rawNode.previousSibling;

  if (prevSibling === null) {
    return true;
  } else if (prevSibling.nodeType !== 1 && prevSibling.nodeType !== 3) {
    // The previous sibling is not an element or text node, so recursively check the one before it
    // e.g it could be a comment node, which doesn't count as a 'real' sibling for our purposes
    return isFirstSibling(prevSibling);
  } else {
    // The previous sibling is an element or text node, so this node is not the first sibling
    return false;
  }
}
