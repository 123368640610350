/*
  This module tests that the DOM is fully loaded.
  i.e. Including our Angular blocks / widgets etc.
  It sets a flag in the DOM to indicate when it has loaded.
*/

module.exports = function ($interval, $timeout) {
  return function AllLoaded(onLoaded) {
    var CHECK_ITERATION_TIME = 250;
    var checking = null;

    var loaded = function () {
      $interval.cancel(checking);
      onLoaded();

      // Set DOM flag for Phantom JS to hook into.
      $("body").attr("loaded-for-pdf", "true");
    };

    var checkForLoaded = function () {
      /**
       * Notes on what each of the strings in this function are:
       * `.quote-module` is a class on the block <section>, a regular part of the dom.
       *    If it exists, we know this is a quote block
       * `.old-quote` is a class for an old deprecated quote block
       * `qwirl-quote` is NOT a class, but a custom tag for an angular directive that constructs
       *    the quote table
       * `.qwilr-quote` is a class for one of the <div>s constructed by the qwilr-quote directive.
       *    Once it exists, we know the quote table has been constructed
       */

      // Currently, only the new quote module is an Angular widget.
      // So if we have no quote modules, or we're using the old static quote
      // then the DOM will load predictably and $(document).ready() will do the trick.
      if (!$(".quote-module").length || $(".quote-module .old-quote").length) {
        // This is not a quote block, so we don't need any more checking
        loaded();
      }
      // Otherwise, lets check if each of the quote Angular widgets are loaded...
      else {
        var allLoaded = true;

        // Check each qwilr-quote directive to see if it has been constructed
        $("qwilr-quote").each(function () {
          if (!$(this).find(".qwilr-quote").length) {
            allLoaded = false;
          }
        });

        if (allLoaded) {
          loaded();
        }
      }
    };

    var documentReady = false;
    var windowReady = false;

    /**
     * When the document and window are ready, start
     * checking if Angular widgets are loaded
     */
    var startCheckingIfReady = function () {
      if (documentReady && windowReady /*&& waitReady*/) {
        checking = $interval(checkForLoaded, CHECK_ITERATION_TIME);
      }
    };

    // Document ready() ensures the HTML is loaded.
    $(document).ready(function () {
      documentReady = true;
      startCheckingIfReady();
    });

    // Window on load() ensures that images are all loaded.
    if (document.readyState === "complete") {
      windowReady = true;
      startCheckingIfReady();
    } else {
      $(window).on("load", function () {
        windowReady = true;
        startCheckingIfReady();
      });
    }

    /**
     * @NOTE (som, 2017-08-09): AllLoaded used to wait 200ms before checking for
     * document and window ready. This seems to be a leftover from a time when it was
     * only checking for document ready, and was basically serving the purpose of
     * waiting for window on load
     *
     * In case I'm wrong and the 200ms wait serves another purpose, use this bit of code
     * (and also check for waitReady in startCheckingIfReady()) to add the waiting back in
     */
    // var waitReady = false
    // $timeout ( function() {
    //   waitReady = true
    //   startCheckingIfReady()
    // }, 200)

    /**
     * If this is taking a long time, report to our internal logging
     */
    var WARNING_TIMEOUT = 60000;
    $timeout(function () {
      if (!documentReady || !windowReady) {
        // PhantomJS errors are reported as server errors
        console.error("allLoaded() has been waiting for " + WARNING_TIMEOUT + "ms, and the page still hasn't loaded");
      }
    }, WARNING_TIMEOUT);
  };
};
