"use strict";
var localAssets = "/Public/Assets/";
var imageLocation = {};
var locations = {
    local: function (fileName) {
        return localAssets + fileName;
    },
};
for (var functionName in locations) {
    var locationFn = locations[functionName];
    imageLocation[functionName] = locationFn;
}
module.exports = imageLocation;
