// Generated by CoffeeScript 1.12.7
(function () {
  angular
    .module("qwilrProjectView", [
      "ngSanitize",
      "ngAnimate",
      "create-ui",
      "mgcrea.ngStrap.tooltip",
      require("../Application/Quote/Directive").name,
      require("../Application/QuoteV2/Directive").name,
      require("AcceptButtonv2/Directive").name,
      require("DomTrick/DomTrick").name,
      require("AcceptV2/Directive")["default"].name,
      require("../Application/Widgets/MountWidgetsDirective/MountWidgetsDirective")["default"].name,
    ])
    .run(function ($templateCache) {
      return (window.$templateCache = $templateCache);
    })
    .controller("QwilrProjectViewController", function ($scope, $rootScope, $injector, $timeout) {
      var Injector, di, injectables, wiringConfig;
      Injector = require("hxroin");
      di = new Injector($injector);
      wiringConfig = require("../Application/Common/Bootstrapper/Wiring");
      injectables = di.invoke(wiringConfig);
      di.load(injectables);
      di.value("projectViewMode", true);
      return ($rootScope.di = di);
    });
}.call(this));
