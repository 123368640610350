"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerBlockEnteredViewportObservers = void 0;
var Sentry = __importStar(require("@sentry/browser"));
var Util_1 = require("../../Util");
var PollingElementSelector_1 = require("../Utils/PollingElementSelector");
var Event_1 = require("./Event");
var blockOrder = [];
var registerBlockEnteredViewportObservers = function (eventSender, setNewBlockInteractionGroupId) { return __awaiter(void 0, void 0, void 0, function () {
    var observer, blocks, blocks_1, blocks_1_1, block, blockId;
    var e_1, _a;
    var _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                observer = new IntersectionObserver(function (event) { return handleBlockEnteredViewportEvent(event, eventSender, setNewBlockInteractionGroupId); }, {
                    threshold: 0.3,
                });
                return [4, (0, PollingElementSelector_1.pollingElementSelector)({ selector: 'section[data-qwilr-component="block"' })];
            case 1:
                blocks = _d.sent();
                if (!blocks)
                    return [2];
                try {
                    for (blocks_1 = __values(blocks), blocks_1_1 = blocks_1.next(); !blocks_1_1.done; blocks_1_1 = blocks_1.next()) {
                        block = blocks_1_1.value;
                        blockId = (_c = (_b = block.attributes) === null || _b === void 0 ? void 0 : _b.getNamedItem("block-id")) === null || _c === void 0 ? void 0 : _c.value;
                        if (blockId)
                            blockOrder.push(blockId);
                        observer.observe(block);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (blocks_1_1 && !blocks_1_1.done && (_a = blocks_1.return)) _a.call(blocks_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                return [2];
        }
    });
}); };
exports.registerBlockEnteredViewportObservers = registerBlockEnteredViewportObservers;
var handleBlockEnteredViewportEvent = function (intersectionEvents, eventSender, setNewBlockInteractionGroupId) { return __awaiter(void 0, void 0, void 0, function () {
    var blockDataForNewlyEnteredViewport, pageInfo_1, blockEnteredViewportEvents, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                blockDataForNewlyEnteredViewport = intersectionEvents.reduce(function (blockData, intersectionEvent) {
                    var _a, _b, _c, _d, _e, _f;
                    var isIntersecting = intersectionEvent.isIntersecting, target = intersectionEvent.target;
                    if (!isIntersecting)
                        return blockData;
                    var blockId = (_b = (_a = target === null || target === void 0 ? void 0 : target.attributes) === null || _a === void 0 ? void 0 : _a.getNamedItem("block-id")) === null || _b === void 0 ? void 0 : _b.value;
                    if (!blockId)
                        return blockData;
                    setNewBlockInteractionGroupId(blockId);
                    var blockType = (_d = (_c = target === null || target === void 0 ? void 0 : target.attributes) === null || _c === void 0 ? void 0 : _c.getNamedItem("data-type")) === null || _d === void 0 ? void 0 : _d.value;
                    var blockName = (_f = (_e = target === null || target === void 0 ? void 0 : target.attributes) === null || _e === void 0 ? void 0 : _e.getNamedItem("block-name")) === null || _f === void 0 ? void 0 : _f.value;
                    if (!blockType || !blockName)
                        return blockData;
                    var blockIndex = blockOrder.indexOf(blockId);
                    return __spreadArray(__spreadArray([], __read(blockData), false), [{ blockId: blockId, blockType: blockType, blockIndex: blockIndex, blockName: blockName }], false);
                }, []);
                if (!blockDataForNewlyEnteredViewport.length)
                    return [2];
                pageInfo_1 = (0, Util_1.getPageTrackingInfo)(window);
                if (!pageInfo_1) {
                    Sentry.captureException(new Error("Missing pageInfo in block viewport entry tracking monitoring"));
                    return [2];
                }
                blockEnteredViewportEvents = blockDataForNewlyEnteredViewport.map(function (_a) {
                    var blockId = _a.blockId, blockIndex = _a.blockIndex, blockType = _a.blockType, blockName = _a.blockName;
                    return (0, Event_1.formatBlockEnteredViewportEvent)({
                        pageInfo: pageInfo_1,
                        blockId: blockId,
                        blockIndex: blockIndex,
                        blockType: blockType,
                        blockName: blockName,
                    });
                });
                return [4, eventSender.send(blockEnteredViewportEvents)];
            case 1:
                _a.sent();
                return [3, 3];
            case 2:
                error_1 = _a.sent();
                Sentry.captureException(error_1);
                return [3, 3];
            case 3: return [2];
        }
    });
}); };
