"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.roiCalcVariableChangeHandler = exports.registerROICalculatorButtonClick = void 0;
var Util_1 = require("../../../Util");
var Event_1 = require("./Event");
var PollingElementSelector_1 = require("../../Utils/PollingElementSelector");
var RegisterInteractionListeners_1 = require("../../RegisterInteractionListeners");
var Selectors_1 = require("../../Utils/Selectors");
var SentryAnalyticsError_1 = require("../../Utils/SentryAnalyticsError");
var roiShowHideVariablesButtonSelector = 'div[data-qwilr-component="show-hide-roi-calc-button"]';
var inputValues = {};
var registerROICalculatorButtonClick = function (eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        $(Selectors_1.SECTION_BLOCK_SELECTOR).on("click", roiShowHideVariablesButtonSelector, function (event) { return __awaiter(void 0, void 0, void 0, function () {
            var target, widgetId, contentWrapper, inputs, inputs_1, inputs_1_1, input, currentValue, inputId;
            var e_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        target = $(event.target);
                        widgetId = target.attr("data-qwilr-widgetid") ||
                            target.parents(roiShowHideVariablesButtonSelector).attr("data-qwilr-widgetid");
                        if (!widgetId)
                            return [2];
                        return [4, (0, PollingElementSelector_1.pollingElementSelector)({
                                selector: "div[data-qwilr-component=\"roi-calculator-panel\"][data-qwilr-widgetid=\"".concat(widgetId, "\"]"),
                            })];
                    case 1:
                        contentWrapper = _b.sent();
                        if (!contentWrapper)
                            return [2];
                        inputs = contentWrapper.find("input");
                        try {
                            for (inputs_1 = __values(inputs), inputs_1_1 = inputs_1.next(); !inputs_1_1.done; inputs_1_1 = inputs_1.next()) {
                                input = inputs_1_1.value;
                                currentValue = $(input).attr("value");
                                inputId = $(input).attr("id");
                                if (!currentValue || !inputId)
                                    continue;
                                inputValues[inputId] = currentValue;
                                $(input).on("blur", function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    return [2, (0, exports.roiCalcVariableChangeHandler)(event, eventSender, widgetId)];
                                }); }); });
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (inputs_1_1 && !inputs_1_1.done && (_a = inputs_1.return)) _a.call(inputs_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return [2];
                }
            });
        }); });
        return [2];
    });
}); };
exports.registerROICalculatorButtonClick = registerROICalculatorButtonClick;
var roiCalcVariableChangeHandler = function (event, eventSender, widgetId) { return __awaiter(void 0, void 0, Promise, function () {
    var target, inputId, newValue, previousValue, labelId, variableName, roiCalculatorLabelElem, pageInfo, _a, blockId, blockType, blockName, interactionGroupId, variableChangeEvent, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                target = $(event.target);
                inputId = target.attr("id");
                if (!inputId)
                    return [2];
                newValue = target.attr("value");
                if (!newValue)
                    return [2];
                previousValue = inputValues[inputId];
                if (newValue === previousValue)
                    return [2];
                inputValues[inputId] = newValue;
                labelId = target.attr("aria-labelledby");
                variableName = $("label#".concat(labelId)).text();
                roiCalculatorLabelElem = $("div[data-qwilr-component=\"roi-calculator-label\"][data-qwilr-widgetid=\"".concat(widgetId, "\"]"));
                pageInfo = (0, Util_1.getPageTrackingInfo)(window);
                _a = (0, Util_1.blockDetailsForChildElement)(target), blockId = _a.blockId, blockType = _a.blockType, blockName = _a.blockName;
                if (!pageInfo || !blockId || !blockType || !blockName) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing page/block metadata in ROI variable monitoring"),
                        source: "ROI variable",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                            blockName: blockName,
                            blockType: blockType,
                        },
                    });
                    return [2];
                }
                interactionGroupId = RegisterInteractionListeners_1.blockIdInteractionGroupIdMap[blockId];
                if (!interactionGroupId)
                    return [2];
                variableChangeEvent = (0, Event_1.formatROICalculatorVariableChangeEvent)({
                    pageInfo: pageInfo,
                    blockId: blockId,
                    termValue: parseInt(newValue, 10),
                    term: variableName,
                    roiCalculatorLabel: roiCalculatorLabelElem.length ? roiCalculatorLabelElem.text() : undefined,
                    interactionGroupId: "".concat(interactionGroupId, "-").concat(widgetId),
                    blockName: blockName,
                    blockType: blockType,
                });
                return [4, eventSender.send([variableChangeEvent])];
            case 1:
                _b.sent();
                return [3, 3];
            case 2:
                error_1 = _b.sent();
                (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                    error: error_1,
                    source: "ROI variable",
                    description: "ROI variable interaction caught in try/catch",
                });
                return [3, 3];
            case 3: return [2];
        }
    });
}); };
exports.roiCalcVariableChangeHandler = roiCalcVariableChangeHandler;
