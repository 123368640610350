require("qed");

/**
 * Given a list of top-level dom elements for a block, place the elements onto one or more
 * virtual pages, splitting elements across pages if necessary
 *
 * Function call hierarchy:
 * PrintFormatter > addAllElems() > addElem() (can recurse) > splitElemByHeight() >
 * splitNodeByHeight() (can recurse) > [if textNode] divideTextNode()
 *
 * @param options
 *   `rootElem` - root container DOM element, inside of which the height-constrained containers are added
 *   `contentElems` - the content to divide across the container elements
 *   `containerClass` - class name for the container (of a constant specified height) that we divide contents into
 *   `HEIGHT` - the height of the container elements.
 */
module.exports = function divideAcross(options) {
  // Extract options
  var rootElem = options.rootElem;
  var contentElems = options.contentElems;
  var containerClass = options.containerClass || "print-virtual-page";
  var HEIGHT = options.HEIGHT;

  // Helper function for dividing text nodes
  var divideTextNode = require("./DivideTextNode").splitTextNodeAtHeight;

  // Helper functions for splitting and repairing the dom with qed
  var slicing = require("./DomSlicing");
  var sliceUpwards = slicing.sliceUpwards;
  var joinUpwards = slicing.joinUpwards;

  // Helper function for doing any dom modifications on the back half of a split element
  var modifyBackElement = require("./ModifyBackElement");

  // If we've filled up more than this much of a page, we don't try to split the
  // next element across the remaining space and just add it to a new page instead
  var NEW_PAGE_THRESHOLD = HEIGHT * 0.85;

  // @TODO (som, 2017-08-18): when splitting long content across virtual pages, we want the
  // part of the content on the next page to be at least this height
  //var MIN_TAIL_THRESHOLD = HEIGHT * 0.1;

  // Track the current container element
  var currentContainer = null;

  if (!contentElems) {
    throw new Error("No contentElems given to divideAcross");
  }
  if (!rootElem) {
    throw new Error("No rootElem given to divideAcross");
  }

  /**
   * The interface function. Add all the elements onto one or more virtual pages
   *
   * @param {element[]} elems list of top-level dom elements of a block
   */
  function addAllElems(elems) {
    // Move all the elements to a temporary virtual page so we have the correct styling
    // applied when checking outerHeight()
    var tempVirtualPage = moveToTemporaryPage(elems);

    // Always start a new page, even if there are no elements (e.g an empty splash block)
    addNewContainer();
    for (var i = 0, count = elems.length; i < count; ++i) {
      addElem(elems[i], false);
    }

    // We're done, so delete the temporary virtual page
    // @TODO (som 2017-08-18): do a sanity check to make sure the page is empty and report
    // an error to Sentry if it isn't
    tempVirtualPage.remove();
  }

  /**
   * Detach all nodes in elems and attach them to a temporary virtual page
   *
   * @param {element[]} elems
   * @returns
   */
  function moveToTemporaryPage(elems) {
    var tempVirtualPage = $("<div>").addClass(containerClass);

    // Set an id so we can easily distinguish the temporary page from the actual pages
    // when visually looking at the dom
    tempVirtualPage.attr("id", "temporary-virtual-page");
    tempVirtualPage.appendTo(rootElem);

    // @NOTE (som, 2017-08-17): we don't want to use foreach with an anonymous function,
    // as this will cause the anonymous function to be created for every step of the loop
    for (var i = 0, count = elems.length; i < count; ++i) {
      $(elems[i]).detach().appendTo(tempVirtualPage);
    }

    return tempVirtualPage;
  }

  /**
   * Add an element to the current virtual page. Recursively splits it into a new page if necessary
   *
   * @param {element} elem the dom element to add
   * @param {bool} addEvenIfTooBig should this element be added even if it doesn't fit on the page?
   * We need this to prevent a big unsplittable element from being infinitely pushed to the next page
   */
  function addElem(elem, startNewPage) {
    startNewPage = startNewPage || false;

    elem = $(elem);

    if (startNewPage) {
      addNewContainer();
    }

    var combinedHeight = getCombinedHeight(elem);

    if (combinedHeight < HEIGHT) {
      // Stopping condition: the element will fit on the current page
      addToContainer(elem);
    } else {
      // The element won't fit on the current page

      if (currentContainer.outerHeight() >= NEW_PAGE_THRESHOLD) {
        // There's not much room left on the current page, so just start
        // a new page and try again (instead of trying to split the element)
        // This is to prevent e.g a single line of a new paragraph being added
        // to the end of a page
        addElem(elem, true);
      } else {
        // There is enough room on the current page, so split the element up

        // How much height do we have left to work with in our container?
        var remainingHeight = HEIGHT - currentContainer.outerHeight();

        // Split the element in 2, fitting as much of it on the remaining height
        // of the current page as possible
        var splitElems = splitElemByHeight(elem, remainingHeight);
        var frontElem = splitElems[0]; // The part that fits on the current page
        var backElem = splitElems[1]; // The part that will be added to the next page

        // Add the newly created and fitted element to the virtual page
        // If the frontElem is null that means we couldn't fit anything more on the current page
        // even after splitting
        if (frontElem !== null) {
          addToContainer(frontElem);
        }

        if (frontElem === null && startNewPage) {
          // Stopping condition: this was a new empty page and we still couldn't fit any part of
          // this element in it. Put the element in our multi-page container hack to at least make
          // sure we don't lose any content
          createMultiPageContainer(elem);
        } else {
          // Recursively add the back half of the split element to a new page
          addElem(backElem, true);
        }
      }
    }
  }

  /**
   * Split the element in 2, so that the first section fits within the given height
   *
   * @param {jquery selector} elem
   * @param {number} height
   * @param {bool} rebalancing whether we are calling this again just to rebalance the amount of
   * content on the next page
   * @returns
   */
  function splitElemByHeight(elem, height, rebalancing) {
    rebalancing = rebalancing || false;

    var frontElem;
    var backElem;

    var startingChildNodes = elem[0].childNodes.length;

    // Split elem so all that remains fits in the given height
    // This is an in-place operation that modifies elem
    var point = splitNodeByHeight(elem, elem, height);

    /**
     * Check for the case where the split point is actually the very front of the element we were
     * trying to split. This tells us that the element could not be split by splitNodeByHeight()
     * at the given height, so we should add it to the next page instead
     *
     * We also need to check for empty text content, as this means a text node was split on the 0th
     * index of the text (which is logically the same as the front of the elem)
     *
     * @TODO (som, 2017-08-22): find a more efficient way of checking for an empty
     * <p>, <h1>, etc other than elem.text()
     */
    var isFrontOfElem = point.type === qed.Point.types.BEFORE && $(point.node).is(elem);
    if (isFrontOfElem || elem.text() === "") {
      // The element didn't fit and was unsplittable, so don't add
      // anything else to the current page
      frontElem = null;
      backElem = elem;
    } else {
      // The element was split properly
      frontElem = elem;
      backElem = elem.next();

      var endingChildNodes = frontElem[0].childNodes.length + backElem[0].childNodes.length;

      // Some elements need modification when they've been split,
      // e.g we remove the quote mark on blockquotes
      modifyBackElement(frontElem, backElem, startingChildNodes !== endingChildNodes);
    }

    // @TODO (som, 2017-08-18): put this rebalancing back in at some point, or find a better way
    // to do it without repeating the entire search process.
    // Rebalancing will need to be done before any modifications to the back element

    // // If the back element is too short, we try again at a height that leaves
    // // the back element at a decent height. e.g if 1 line of a paragraph spills over to the
    // // next page, we try again at a height that will leave ~3 lines on the next page
    // if (!rebalancing && backElem.outerHeight() < MIN_TAIL_THRESHOLD) {
    //   console.log("Back was too short, trying again. It was height", backElem.outerHeight())

    //   point.joinLeft()
    //   backElem = splitElemByHeight(elem, height - MIN_TAIL_THRESHOLD, true)
    // }

    return [frontElem, backElem];
  }

  /**
   * Split the node at the point where it fills up the page.
   * This recurses down the dom until it reaches an unsplittable element or a text node
   *
   * @TODO (som, 2017-08-18): rewrite this as a binary search if it's too slow. We'd want to break
   * this up into 2 functions - one that just does the binary search to find the first node that
   * goes over the height (similar to the DivideTextNode binary search), and another that chooses
   * how to split the node (split before, split text node, or recurse into children)
   *
   * @param {jquery selector} elementFront a reference to the part of the dom we are trying to add to the page
   * This reference should not change at any point as it gets passed down (although the dom it references does change)
   * @param {jquery selector} node the node we are currently trying to split. Should be an element or text node
   * @param {number} height the height we are trying to split at
   * @param {integer} recurseDepth how deep the recursion of this function currently is. Only used for debugging
   * @returns {qed.Point}
   */
  function splitNodeByHeight(elementFront, node, height, recurseDepth) {
    recurseDepth = recurseDepth || 0;

    // Whenever we slice up the dom, we stop at the top ancestor
    // (which should be the temporary-virtual-page)
    var topAncestor = elementFront.parent();

    // Store a convenience reference to the raw dom node, since a lot of functions need it
    var rawNode = node.get(0);

    // Do a test split to see what the resulting height is
    var point = qed.Point.after(rawNode);
    var splitLevels = sliceUpwards(point, topAncestor);
    var elementFrontHeight = elementFront.outerHeight();

    // Repair the test split
    joinUpwards(point, splitLevels);

    // Is this the first node that causes the entire element to go over the target height?
    if (elementFrontHeight >= height) {
      if (isUnsplittable(node)) {
        // Stopping case: this is an unsplittable 'leaf' node, so we actually want to slice
        // just before this node since this node goes over the page

        // Slice the dom before this node
        point = qed.Point.before(rawNode);
        sliceUpwards(point, topAncestor);
      } else if (rawNode.nodeType === 3) {
        // Stopping case: this is a text node, so we don't want to recurse into it

        // Divide the text node to fit into the given height
        point = divideTextNode(elementFront, rawNode, height, topAncestor);
      } else {
        // This node goes over the page and is recursively splittable
        // Find the splitting point in the children

        // We only split on regular elements and text nodes
        var childNodes = _.filter(node.contents(), function (c) {
          return c.nodeType === 1 || c.nodeType === 3;
        });

        for (var i = 0; i < childNodes.length; ++i) {
          var child = childNodes[i];

          // Make sure the child hasn't been orphaned from dom manipulation
          if (child.parentNode !== null) {
            point = splitNodeByHeight(elementFront, $(child), height, recurseDepth + 1);
            if (point !== null) {
              // Stopping case: the child recursion has found the correct split for this height
              break;
            }
          } else {
            // The child was orphaned!
            /**
             * @NOTE (som 2017-08-18): I've managed to trigger this in only one situation - where
             * the page splits the middle of the quote totals table, in between the subtotal
             * and total rows. I've only ever gotten it to orphan spacing text nodes that don't
             * affect the rendering, so it might not be a problem
             */
            console.warn(
              "A child node was orphaned during a recursive splitNodeByHeight(). Parent:",
              rawNode,
              "Child:",
              child,
            );
          }
        }
      }

      return point;
    } else {
      // This element is still below the target height, so tell the caller we shouldn't split here
      return null;
    }
  }

  /**
   * Is this node a 'leaf' node that we shouldn't try to split?
   *
   * @param {jquery selector} node
   * @returns {bool}
   */
  function isUnsplittable(node) {
    var rawNode = node.get(0);
    var tagName = rawNode.tagName;

    // Don't split <br> tags
    // Don't split table rows (e.g quote items)
    // Don't split insertables (e.g inline images)
    // Don't split the Accept button
    // Don't split video block print placeholders
    // Don't split Quote section headers or individual line items
    // @TODO (som, 2017-08-22): allow splitting of table widgets, which are insertables
    return (
      _.includes(["BR", "TR", "INSERTABLE", "PUBLIC-ACCEPT-BUTTON", "FIGURE"], tagName) ||
      _.includes(rawNode.className, "print-placeholder") ||
      _.includes(rawNode.className, "quote-item ") ||
      _.includes(rawNode.className, "section-header") ||
      _.includes(rawNode.className, "side-by-side")
    );
  }

  function addNewContainer() {
    var container = $("<div></div>").addClass(containerClass);
    currentContainer = container.appendTo(rootElem);
  }

  /**
   * What would be the height of the page content if we added elem to it?
   *
   * @param {jquery selector} elem
   * @returns {number}
   */
  function getCombinedHeight(elem) {
    return elem.outerHeight() + currentContainer.outerHeight();
  }

  function addToContainer(elem) {
    return elem.detach().appendTo(currentContainer);
  }

  /**
   * Make a special container that will fit all the content in elem without any dom splitting
   * @NOTE (som, 2017-08-28): this is an original @HACK from Dylan that was in the old PDF code
   *
   * @param {jquery selector} elem the element to put on the page
   */
  function createMultiPageContainer(elem) {
    // Mark the container of this element with a special class
    // which removes the overflow CSS rule.
    addToContainer(elem);
    var container = elem.parents("." + containerClass);
    container.addClass("too-tall-child");

    // Make the resulting element length be a multiple of the page height
    // so the next virtual page begin cleanly.
    var heightRemainder = HEIGHT - (container.outerHeight() % HEIGHT);
    container.css("height", container.outerHeight() + heightRemainder);
  }

  // INIT
  addAllElems(contentElems);
};
