// Generated by CoffeeScript 1.12.7
(function () {
  var CornerBadgeController, LogoColor;

  LogoColor = require("Common/Utils/ImageImports").LogoColor;

  module.exports = CornerBadgeController = (function () {
    function CornerBadgeController(createUi, imageLocation, badgeClickUrl) {
      this.ui = createUi(require("./CornerBadge.html"), {
        url:
          "//" +
          badgeClickUrl +
          "/?utm_source=qwilr-branding&utm_medium=qwilr-badge&utm_campaign=made-with-qwilr-unfurled",
        logoColor: LogoColor,
      });
    }

    return CornerBadgeController;
  })();
}.call(this));
