/**
 * Helper function for splitting a text node at a given height
 */

module.exports = {
  splitTextNodeAtHeight: splitTextNodeAtHeight,
};

// Functions for slicing and repairing the dom
var slicing = require("./DomSlicing");
var sliceUpwards = slicing.sliceUpwards;
var joinUpwards = slicing.joinUpwards;

/**
 * Split a text node at a given height
 *
 * @param {jquery selector} elementFront a reference to the part of the dom we are trying to add to the page.
 * We use this to check the .outerHeight() of the entire 'front' of the dom when we split it in 2
 * @param {textNode} textNode the node we are trying to split. Must be a text node (i.e .nodeType === 3)
 * @param {number} height we want the .outerHeight() of elementFront to be just below this height
 * @returns {qed.Point} the point used for the qed dom splitting
 */
function splitTextNodeAtHeight(elementFront, textNode, height) {
  // Find the offset in the text that will just fit under the height
  var offset = findTextSplitRecursive(elementFront, textNode, height, 0, textNode.data.length);

  // Move the offset back to the start of whatever word is at `offset`
  // This is so we don't split the paragraph in the middle of a word
  offset = findWordStart(textNode.data, offset);

  // Split the dom at the text offset we calculated
  var point = qed.Point.text(textNode, offset);
  sliceUpwards(point, elementFront.parent());

  return point;
}

/**
 * Do a binary search through a text node to find the point where it fits
 * just below the target height
 *
 * elementFront, textNode, and height are all just passed through from
 * splitTextNodeAtHeight() and are not modified
 *
 * @param {jquery selector} elementFront
 * @param {textNode} textNode
 * @param {number} height
 * @param {index} start the start of our search space for this recursion
 * @param {index} end the end of our search space for this recursion
 * @returns the text offset where the split should occur
 */
function findTextSplitRecursive(elementFront, textNode, height, start, end) {
  if (end - start <= 1) {
    // Stopping condition: we've covered our search space
    // The height at `start` should be just below the target height
    return start;
  } else {
    // Get the halfway position of our search space
    var bisectPosition = Math.floor((start + end) / 2);

    // Do a test split of the text node at the halfway position
    var point = qed.Point.text(textNode, bisectPosition);
    var splitLevels = sliceUpwards(point, elementFront.parent());

    var newStart = start;
    var newEnd = end;

    // When we split here, are we above or below the target height?
    // Halve the search space accordingly
    if (elementFront.outerHeight() >= height) {
      newEnd = bisectPosition;
    } else {
      newStart = bisectPosition;
    }

    // Repair the test split
    joinUpwards(point, splitLevels);

    // Do the next step of the binary search on the halved search space
    return findTextSplitRecursive(elementFront, textNode, height, newStart, newEnd);
  }
}

/**
 * Find the start of the word that is at the `offset` position of the string
 *
 * e.g if the offset is here:
 * "The quick brown fox"
 *         ^
 *
 * Then return the index of here:
 * "The quick brown fox"
 *      ^
 *
 * @param {string} text
 * @param {integer} offset
 * @return the index of the start of the word
 */
function findWordStart(text, offset) {
  // @NOTE (som, 2017-08-21): this list of whitespace characters is taken from:
  // https://stackoverflow.com/questions/1496826/check-if-a-single-character-is-a-whitespace
  var WHITESPACE_CHARS = " \t\n\r\v";

  // Step backwards until we find the start of the word
  for (var i = offset; i > 0; --i) {
    // Is the previous character whitespace?
    if (WHITESPACE_CHARS.indexOf(text.charAt(i - 1)) > -1) {
      // If it is, then the current index is the start of the word
      return i;
    }
  }
  // If we reach the end of the loop, then the start of the word is actually the
  // start of the string
  return 0;
}
