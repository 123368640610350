"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventType = void 0;
var EventType;
(function (EventType) {
    EventType["PageDurationEvent"] = "PageViewDuration";
    EventType["BlockViewDuration"] = "BlockViewDuration";
    EventType["OutboundLinkClick"] = "OutboundLinkClick";
    EventType["PageInteraction"] = "PageInteraction";
})(EventType || (exports.EventType = EventType = {}));
