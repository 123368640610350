"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerPlanCardQuantityStepClickListener = exports.registerPlanCardQuantityInputChangeListener = void 0;
var Util_1 = require("../../../../Util");
var PollingElementSelector_1 = require("../../../Utils/PollingElementSelector");
var Event_1 = require("./Event");
var RegisterInteractionListeners_1 = require("../../../RegisterInteractionListeners");
var SentryAnalyticsError_1 = require("../../../Utils/SentryAnalyticsError");
var planCardQuantityInputMap = {};
var registerPlanCardQuantityInputChangeListener = function (eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    var planCardInputSelector, planCardInputs, planCardInputs_1, planCardInputs_1_1, input, selectedInput, id, value;
    var e_1, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                planCardInputSelector = 'input[data-qwilr-component="plan-card-quantity"]';
                return [4, (0, PollingElementSelector_1.pollingElementSelector)({ selector: planCardInputSelector })];
            case 1:
                planCardInputs = _b.sent();
                if (!planCardInputs)
                    return [2];
                try {
                    for (planCardInputs_1 = __values(planCardInputs), planCardInputs_1_1 = planCardInputs_1.next(); !planCardInputs_1_1.done; planCardInputs_1_1 = planCardInputs_1.next()) {
                        input = planCardInputs_1_1.value;
                        selectedInput = $(input);
                        id = selectedInput.attr("aria-labelledby");
                        value = selectedInput.attr("value");
                        if (typeof id === "undefined" || typeof value === "undefined")
                            continue;
                        planCardQuantityInputMap[id] = parseInt(value, 10);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (planCardInputs_1_1 && !planCardInputs_1_1.done && (_a = planCardInputs_1.return)) _a.call(planCardInputs_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                planCardInputs.on("blur", function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, handlePlanCardInputBlur(event, eventSender)];
                }); }); });
                return [2];
        }
    });
}); };
exports.registerPlanCardQuantityInputChangeListener = registerPlanCardQuantityInputChangeListener;
var handlePlanCardInputBlur = function (event, eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    var target, valueAttr, id, value, existingValue, pageInfo, _a, blockId, blockName, blockType, interactionGroupId, formattedEvent, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                target = $(event.target);
                valueAttr = target.attr("value");
                id = target.attr("aria-labelledby");
                if (!valueAttr || !id)
                    return [2];
                value = parseInt(valueAttr, 10);
                existingValue = planCardQuantityInputMap[id];
                if (value === existingValue)
                    return [2];
                planCardQuantityInputMap[id] = value;
                pageInfo = (0, Util_1.getPageTrackingInfo)(window);
                _a = (0, Util_1.blockDetailsForChildElement)(target), blockId = _a.blockId, blockName = _a.blockName, blockType = _a.blockType;
                if (!pageInfo || !blockId || !blockType || !blockName) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing page/block metadata in Plan card quantity change monitoring"),
                        source: "Plan card quantity change",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                            blockName: blockName,
                            blockType: blockType,
                        },
                    });
                    return [2];
                }
                interactionGroupId = RegisterInteractionListeners_1.blockIdInteractionGroupIdMap[blockId];
                if (!interactionGroupId)
                    return [2];
                formattedEvent = (0, Event_1.formatPlanCardQuantityUpdatedEvent)({
                    pageInfo: pageInfo,
                    blockId: blockId,
                    value: value,
                    interactionGroupId: interactionGroupId,
                    blockType: blockType,
                    blockName: blockName,
                });
                return [4, eventSender.send([formattedEvent])];
            case 1:
                _b.sent();
                return [3, 3];
            case 2:
                error_1 = _b.sent();
                (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                    error: error_1,
                    source: "Plan card quntity change",
                    description: "Plan card quantity change interaction caught in try/catch",
                });
                return [3, 3];
            case 3: return [2];
        }
    });
}); };
var registerPlanCardQuantityStepClickListener = function (eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    var upStepSelector, downStepSelector, upSteps, downSteps;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                upStepSelector = "button[data-qwilr-component=\"plan-card-quantity-up-step\"]";
                downStepSelector = "button[data-qwilr-component=\"plan-card-quantity-down-step\"]";
                return [4, (0, PollingElementSelector_1.pollingElementSelector)({ selector: upStepSelector })];
            case 1:
                upSteps = _a.sent();
                return [4, (0, PollingElementSelector_1.pollingElementSelector)({ selector: downStepSelector })];
            case 2:
                downSteps = _a.sent();
                if (!upSteps || !downSteps)
                    return [2];
                upSteps.on("click", function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, handlePlanCardItemQuantityStepClick(event, eventSender, "up")];
                }); }); });
                downSteps.on("click", function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, handlePlanCardItemQuantityStepClick(event, eventSender, "down")];
                }); }); });
                return [2];
        }
    });
}); };
exports.registerPlanCardQuantityStepClickListener = registerPlanCardQuantityStepClickListener;
var deriveValue = function (valueAttr, direction) {
    var value = parseInt(valueAttr, 10);
    if (value === 1 && direction === "down")
        return 1;
    if (direction === "down")
        return value - 1;
    return value + 1;
};
var handlePlanCardItemQuantityStepClick = function (event, eventSender, direction) { return __awaiter(void 0, void 0, Promise, function () {
    var target, quantitySection, input, id, valueAttr, value, existingValue, pageInfo, _a, blockId, blockName, blockType, interactionGroupId, formattedEvent, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                target = $(event.target);
                quantitySection = target.parents('div[data-qwilr-component="plan-card-quantity-section"]');
                input = quantitySection.find("input");
                id = input.attr("aria-labelledby");
                valueAttr = input.attr("value");
                if (!id || !valueAttr)
                    return [2];
                value = deriveValue(valueAttr, direction);
                existingValue = planCardQuantityInputMap[id];
                if (value === existingValue)
                    return [2];
                planCardQuantityInputMap[id] = value;
                pageInfo = (0, Util_1.getPageTrackingInfo)(window);
                _a = (0, Util_1.blockDetailsForChildElement)(target), blockId = _a.blockId, blockName = _a.blockName, blockType = _a.blockType;
                if (!pageInfo || !blockId || !blockName || !blockType) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing page/block metadata in plan card quantity monitoring"),
                        source: "Plan card quantity monitoring",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                            blockName: blockName,
                            blockType: blockType,
                        },
                    });
                    return [2];
                }
                interactionGroupId = RegisterInteractionListeners_1.blockIdInteractionGroupIdMap[blockId];
                if (!interactionGroupId)
                    return [2];
                formattedEvent = (0, Event_1.formatPlanCardQuantityUpdatedEvent)({
                    pageInfo: pageInfo,
                    blockId: blockId,
                    value: value,
                    interactionGroupId: interactionGroupId,
                    blockType: blockType,
                    blockName: blockName,
                });
                return [4, eventSender.send([formattedEvent])];
            case 1:
                _b.sent();
                return [3, 3];
            case 2:
                error_2 = _b.sent();
                (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                    error: error_2,
                    source: "Plan card quantity",
                    description: "Plan card quantity interaction caught in try/catch",
                });
                return [3, 3];
            case 3: return [2];
        }
    });
}); };
