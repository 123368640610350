"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.startPresentationMode = void 0;
var PresentationModeControls_1 = require("./PresentationModeControls");
function startPresentationMode() {
    document.documentElement.requestFullscreen();
    document.body.classList.add("presentation-mode");
    document.documentElement.addEventListener("fullscreenchange", onFullScreenChange);
    (0, PresentationModeControls_1.mountPresentationModeControls)({ onExitClick: function () { return document.exitFullscreen(); } });
}
exports.startPresentationMode = startPresentationMode;
function onFullScreenChange() {
    if (document.fullscreenElement)
        return;
    document.body.classList.remove("presentation-mode");
    (0, PresentationModeControls_1.unmountPresentationModeControls)();
}
