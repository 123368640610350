"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.blockFoldUnfoldHandler = exports.registerFoldableBlockButtonListener = void 0;
var Util_1 = require("../../Util");
var HtmlSelectParse_1 = require("../Utils/HtmlSelectParse");
var Event_1 = require("./Event");
var SentryAnalyticsError_1 = require("../Utils/SentryAnalyticsError");
var foldableBlockSelector = 'button[data-qwilr-component="foldable-block-button"]';
var registerFoldableBlockButtonListener = function (eventSender) {
    return $(foldableBlockSelector).on("click", function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2, (0, exports.blockFoldUnfoldHandler)(event, eventSender)];
    }); }); });
};
exports.registerFoldableBlockButtonListener = registerFoldableBlockButtonListener;
var blockFoldUnfoldHandler = function (event, eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    var target, isButtonClick, blockUnfolded, block, blockHtml, h1, h2, pageInfo, _a, blockId, blockName, blockType, foldableBlockEvent, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 5]);
                target = $(event.target);
                isButtonClick = target.attr("data-qwilr-component") === "foldable-block-button";
                blockUnfolded = isButtonClick
                    ? target.attr("aria-expanded") === "false"
                    : target.parent().attr("aria-expanded") === "false";
                if (!blockUnfolded) return [3, 2];
                return [4, new Promise(function (r) { return setTimeout(r, 100); })];
            case 1:
                _b.sent();
                _b.label = 2;
            case 2:
                if (!blockUnfolded)
                    return [2];
                block = isButtonClick ? target.parent().parent() : target.parent().parent().parent();
                blockHtml = block.html();
                h1 = (0, HtmlSelectParse_1.htmlSelectParse)({ html: blockHtml, tag: "h1" });
                h2 = (0, HtmlSelectParse_1.htmlSelectParse)({ html: blockHtml, tag: "h2" });
                pageInfo = (0, Util_1.getPageTrackingInfo)(window);
                _a = (0, Util_1.blockDetailsForChildElement)(target), blockId = _a.blockId, blockName = _a.blockName, blockType = _a.blockType;
                if (!pageInfo || !blockId || !blockType || !blockName) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing page/block metadata in foldable block monitoring"),
                        source: "FoldableBlock",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                            blockName: blockName,
                            blockType: blockType,
                        },
                    });
                    return [2];
                }
                foldableBlockEvent = (0, Event_1.formatFoldableBlockEvent)({
                    pageInfo: pageInfo,
                    blockId: blockId,
                    blockName: blockName,
                    blockType: blockType,
                    h1: h1,
                    h2: h2,
                });
                return [4, eventSender.send([foldableBlockEvent])];
            case 3:
                _b.sent();
                return [3, 5];
            case 4:
                error_1 = _b.sent();
                (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                    error: error_1,
                    source: "FoldableBlock",
                    description: "FoldableBlock interaction caught in try/catch",
                });
                return [3, 5];
            case 5: return [2];
        }
    });
}); };
exports.blockFoldUnfoldHandler = blockFoldUnfoldHandler;
