"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Telemetry_1 = require("Public/Telemetry/Telemetry");
var AcceptTelemetry = (function () {
    function AcceptTelemetry() {
        var _this = this;
        this.track = function (eventName, eventParams) {
            _this.telemetry.track(eventName, eventParams);
        };
        this.debouncedTrack = function (eventName, eventParams, debounceDelay, debounceOptions) {
            if (debounceDelay === void 0) { debounceDelay = 1000; }
            _this.telemetry.debouncedTrack(eventName, eventParams, debounceDelay, debounceOptions);
        };
        this.throttledTrack = function (eventName, eventParams, throttleDelay, throttleOptions) {
            if (throttleDelay === void 0) { throttleDelay = 1000; }
            if (throttleOptions === void 0) { throttleOptions = { trailing: false }; }
            _this.telemetry.throttledTrack(eventName, eventParams, throttleDelay, throttleOptions);
        };
        this.telemetry = Telemetry_1.Telemetry.getInstance();
    }
    return AcceptTelemetry;
}());
exports.default = AcceptTelemetry;
