"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatUserIdleEvent = void 0;
var Types_1 = require("../../Types");
var Types_2 = require("../Types");
var formatUserIdleEvent = function (_a) {
    var pageInfo = _a.pageInfo;
    return ({
        eventType: Types_1.EventType.PageInteraction,
        eventData: {
            viewId: pageInfo.pageViewId,
            pageId: pageInfo.pageId,
            accountId: pageInfo.accountId,
            fullName: pageInfo.fullName,
            viewUserId: pageInfo.viewUserId,
            interactionType: "viewership",
            blockId: null,
            interactionMetadata: {
                interactionName: Types_2.ViewershipInteractionName.UserIdle,
            },
        },
        timestamp: new Date().toISOString(),
    });
};
exports.formatUserIdleEvent = formatUserIdleEvent;
