"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Util_1 = require("../Analytics/Util");
var BrowserListener = (function () {
    function BrowserListener(pageActivity) {
        var _this = this;
        this.pageActivity = pageActivity;
        this.updateFocalBlock = function () {
            var block = _this.getFocalBlock();
            if (!block.is(_this.pageActivity.getFocalBlock())) {
                if (_this.pageActivity.getFocalBlock()) {
                    _this.pageActivity.trackBlockView();
                }
                _this.pageActivity.setNewFocalBlock(block);
            }
        };
        this.getFocalBlock = function () {
            var blocks = _this.getVisibleBlocks();
            var viewportHeight = $(window).height();
            blocks.sort(function (blockA, blockB) {
                var rectA = blockA.getBoundingClientRect();
                var rectB = blockB.getBoundingClientRect();
                var sizeA = Math.min(rectA.bottom, viewportHeight) - Math.max(rectA.top, 0);
                var sizeB = Math.min(rectB.bottom, viewportHeight) - Math.max(rectB.top, 0);
                return sizeB - sizeA;
            });
            return $(blocks[0]);
        };
        this.getVisibleBlocks = function () {
            return (0, Util_1.getVisibleBlocks)();
        };
        $(window).on("scroll", this.updateFocalBlock);
        $(window).on("resize", this.updateFocalBlock);
        this.updateFocalBlock();
    }
    return BrowserListener;
}());
exports.default = BrowserListener;
