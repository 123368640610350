"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatROICalculatorSliderChangeEvent = void 0;
var Types_1 = require("../../../Types");
var Types_2 = require("../../Types");
var HashWidgetId_1 = require("../../Utils/HashWidgetId");
var formatROICalculatorSliderChangeEvent = function (_a) {
    var pageInfo = _a.pageInfo, blockId = _a.blockId, sliderValue = _a.sliderValue, roiCalculatorLabel = _a.roiCalculatorLabel, interactionGroupId = _a.interactionGroupId, blockName = _a.blockName, blockType = _a.blockType;
    var interactionName = Types_2.PageInteractionName.ROICalculatorUpdated;
    var interactionContentIdentifier = roiCalculatorLabel || blockName;
    return {
        eventType: Types_1.EventType.PageInteraction,
        eventData: {
            blockId: blockId,
            viewId: pageInfo.pageViewId,
            pageId: pageInfo.pageId,
            accountId: pageInfo.accountId,
            fullName: pageInfo.fullName,
            viewUserId: pageInfo.viewUserId,
            interactionContentIdentifier: interactionContentIdentifier,
            interactionGroupId: interactionGroupId,
            widgetId: (0, HashWidgetId_1.hashWidgetId)({ interactionName: interactionName, blockId: blockId, interactionContentIdentifier: interactionContentIdentifier }),
            interactionType: "content",
            interactionMetadata: {
                interactionName: interactionName,
                sliderValue: parseInt(sliderValue, 10),
                roiCalculatorLabel: roiCalculatorLabel,
                blockName: blockName,
                blockType: blockType,
            },
        },
        timestamp: new Date().toISOString(),
    };
};
exports.formatROICalculatorSliderChangeEvent = formatROICalculatorSliderChangeEvent;
