"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Sentry = __importStar(require("@sentry/browser"));
var Util_1 = require("./Util");
var RegisterInteractionListeners_1 = require("./ContentInteractions/RegisterInteractionListeners");
var PageTimer_1 = require("./PageTimer");
var InteractionTracker_1 = require("./InteractionTracker");
var Environment_1 = require("@CommonFrontendBackend/Environment");
var ViewRecorder_1 = require("../Common/Bootstrapper/ViewRecorder");
var initAnalytics = function (usingFeature, hostName) {
    try {
        if ((0, ViewRecorder_1.inIframe)())
            return;
        if (!(0, Util_1.isTimingCaptureEnabled)(window))
            return;
        var isLocal = (0, Environment_1.isLocalEnvironment)(hostName);
        var useRealAnalyticsEventSender = "false";
        if (isLocal) {
            var urlParams = new URLSearchParams(window.location.search);
            useRealAnalyticsEventSender = urlParams.get("useRealAnalyticsEventSender");
        }
        var eventSender = (0, Util_1.createEventSender)({
            localAnalytics: useRealAnalyticsEventSender === "true" ? false : isLocal,
        });
        (0, PageTimer_1.startPageTiming)(eventSender);
        (0, InteractionTracker_1.monitorForLinkClicks)(eventSender);
        (0, RegisterInteractionListeners_1.registerInteractionListeners)(eventSender);
    }
    catch (error) {
        Sentry.captureException(error);
    }
};
exports.default = initAnalytics;
