"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageInteractionName = void 0;
var PageInteractionName;
(function (PageInteractionName) {
    PageInteractionName["AccordionOpened"] = "Accordion Opened";
    PageInteractionName["BlockUnfolded"] = "Block Unfolded";
    PageInteractionName["ClickedNavigationItem"] = "Clicked Navigation Item";
    PageInteractionName["ROICalculatorUpdated"] = "ROI Calculator Updated";
    PageInteractionName["ButtonClicked"] = "Button Clicked";
    PageInteractionName["BlockEnteredViewport"] = "Block Entered Viewport";
    PageInteractionName["AcceptProcessStarted"] = "Accept Process Started";
    PageInteractionName["QuoteChanged"] = "Quote Changed";
    PageInteractionName["AgreementViewed"] = "Agreement Viewed";
    PageInteractionName["OutboundLinkClick"] = "Outbound Link Click";
})(PageInteractionName || (exports.PageInteractionName = PageInteractionName = {}));
