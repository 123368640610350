// Generated by CoffeeScript 1.12.7

/*
  This is an Angular directive which creates a Quote.
  Its role is to make() the Quote UI, seeded with
  the initialisation data.
 */

(function () {
  var Quote, QuoteTotalCalculator;

  Quote = require("./Quote");

  QuoteTotalCalculator = require("Quote/Calculator/Total");

  module.exports = angular.module("quote", []).directive("qwilrQuote", function ($rootScope, $timeout) {
    return {
      restrict: "E",
      template: '<div ui="quote.ui"></div>',
      scope: {
        initData: "@",
      },
      link: function (scope, elem, attrs) {
        var childScope, di;
        di = $rootScope.di;
        childScope = di.child();
        childScope.load({
          block: JSON.parse(attrs.initData),
          projectState: function (block) {
            return {
              status: block.$projectStatus,
            };
          },
          recalculate: function (invoke) {
            return invoke(QuoteTotalCalculator);
          },
          currency: function (block) {
            return block.settings.currency;
          },
        });
        return (scope.quote = childScope.make(Quote));
      },
    };
  });
}.call(this));
