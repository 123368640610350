/*
  The banner at the top to encourage users to enter the playground of the template.
 */

(function () {
  var TemplateBanner;

  module.exports = TemplateBanner = (function () {
    function TemplateBanner(createUi, isTemplateDomain, clientUrl, projectName, secretHash) {
      this.ui = createUi(require("./TemplateBanner.html"), {
        isTemplateDomain: isTemplateDomain,
        back: function () {
          window.location.href = "https://qwilr.com/templates";
        },
        projectName: projectName,
        useLink: clientUrl + "/#/signup?with_template=" + secretHash + "&entry_point=template",
      });
    }

    return TemplateBanner;
  })();
}.call(this));
