"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CHECK_ITERATION_TIME = 250;
var WARNING_TIMEOUT = 60000;
var allLoaded = function ($interval, $timeout) {
    return function (onLoaded) {
        var checking = null;
        var loaded = function () {
            $interval.cancel(checking);
            onLoaded().then(function () {
                $("body").attr("loaded-for-pdf", "true");
            });
        };
        var checkForLoaded = function () {
            var allLoaded = true;
            $('.accept-module [data-unfurl-form-for-printing="true"]').each(function () {
                if (!$(this).find("input").length) {
                    allLoaded = false;
                }
            });
            $('[data-hydration-component="QuoteSummaryWidget"]').each(function () {
                if (!$(this).find(".quote-summary-widget").length) {
                    allLoaded = false;
                }
            });
            if (allLoaded) {
                loaded();
            }
        };
        var windowReady = false;
        var startCheckingIfReady = function () {
            checking = $interval(checkForLoaded, CHECK_ITERATION_TIME);
        };
        if (document.readyState === "complete") {
            windowReady = true;
            startCheckingIfReady();
        }
        else {
            $(window).on("load", function () {
                windowReady = true;
                startCheckingIfReady();
            });
        }
        $timeout(function () {
            if (!windowReady) {
                console.error("allLoaded() has been waiting for ".concat(WARNING_TIMEOUT, "ms, and the page still hasn't loaded"));
            }
        }, WARNING_TIMEOUT);
    };
};
exports.default = allLoaded;
