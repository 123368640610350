// Generated by CoffeeScript 1.12.7
(function () {
  var SocialBadgeController, SocialShareUtmMedium, getSocialLinks, ref;

  (ref = require("@CommonFrontend/SocialShare/SocialShareService")),
    (getSocialLinks = ref.getSocialLinks),
    (SocialShareUtmMedium = ref.SocialShareUtmMedium);

  module.exports = SocialBadgeController = (function () {
    function SocialBadgeController(createUi, projectName) {
      var socialLinks;
      socialLinks = getSocialLinks(
        projectName,
        location.protocol + "//" + location.host + location.pathname,
        SocialShareUtmMedium.PublicPageShare,
      );
      this.ui = createUi(require("./SocialBadge.html"), {
        socialLinks: socialLinks,
        socialButtons: [
          {
            platform: "twitter",
            icon: "/Assets/icons/social/Twitter.svg",
          },
          {
            platform: "linkedin",
            icon: "/Assets/icons/social/Linkedin.svg",
          },
          {
            platform: "facebook",
            icon: "/Assets/icons/social/Facebook.svg",
          },
        ],
      });
    }

    return SocialBadgeController;
  })();
}.call(this));
