// Generated by CoffeeScript 1.12.7

/*
  Instantiate a quote block on a public page
 */

(function () {
  module.exports = function (di, createUi, make, block, appEvents, quoteBlocksList, disableInteractives) {
    var makeDisplay, quoteAdapter;
    quoteAdapter = make(require("QuoteV2/Adapter/Adapter"));
    quoteBlocksList.add(block);
    quoteBlocksList.addAdapter(quoteAdapter);
    di.value("clientViewComponents", {});
    makeDisplay = (function (_this) {
      return function (justAccepted) {
        var quoteContent;
        quoteContent = make(require("QuoteV2/View/QuoteContent"), {
          isEditor: false,
          Editor: null,
          quoteAdapter: quoteAdapter,
          disableInteractives: disableInteractives || justAccepted,
        });
        return (_this.ui = createUi(require("./QuoteV2.html"), {
          quoteContent: quoteContent.ui,
        }));
      };
    })(this);
    makeDisplay();
    return appEvents.on("accepted", function () {
      return makeDisplay(true);
    });
  };
}.call(this));
