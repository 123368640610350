"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerLineItemQuantityStepClick = exports.registerLineItemQuantityInputListener = void 0;
var Sentry = __importStar(require("@sentry/browser"));
var Util_1 = require("../../../../Util");
var PollingElementSelector_1 = require("../../../Utils/PollingElementSelector");
var Event_1 = require("./Event");
var RegisterInteractionListeners_1 = require("../../../RegisterInteractionListeners");
var SentryAnalyticsError_1 = require("../../../Utils/SentryAnalyticsError");
var inputSelector = 'input[data-qwilr-component="table-item-quantity-input"]';
var lineItemQuantityMap = {};
var registerLineItemQuantityInputListener = function (eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    var quantityInputs, quantityInputs_1, quantityInputs_1_1, input, selectedInput, row, id, value, error_1;
    var e_1, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                return [4, (0, PollingElementSelector_1.pollingElementSelector)({ selector: inputSelector })];
            case 1:
                quantityInputs = _b.sent();
                if (!quantityInputs)
                    return [2];
                try {
                    for (quantityInputs_1 = __values(quantityInputs), quantityInputs_1_1 = quantityInputs_1.next(); !quantityInputs_1_1.done; quantityInputs_1_1 = quantityInputs_1.next()) {
                        input = quantityInputs_1_1.value;
                        selectedInput = $(input);
                        row = selectedInput.parents('tr[data-qwilr-component="quote-table-row"]');
                        id = row.attr("data-qwilr-component-id");
                        value = selectedInput.attr("value");
                        if (typeof id === "undefined" || typeof value === "undefined")
                            continue;
                        lineItemQuantityMap[id] = parseInt(value, 10);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (quantityInputs_1_1 && !quantityInputs_1_1.done && (_a = quantityInputs_1.return)) _a.call(quantityInputs_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                quantityInputs.on("blur", function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, handleLineItemQuantityBlur(event, eventSender)];
                }); }); });
                return [3, 3];
            case 2:
                error_1 = _b.sent();
                Sentry.captureException(error_1);
                return [3, 3];
            case 3: return [2];
        }
    });
}); };
exports.registerLineItemQuantityInputListener = registerLineItemQuantityInputListener;
var handleLineItemQuantityBlur = function (event, eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    var target, value, row, id, numberValue, existingValue, description, pageInfo, _a, blockId, blockName, blockType, interactionGroupId, lineItemQuantityUpdatedEvent, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                target = $(event.target);
                value = target.attr("value");
                row = target.parents('tr[data-qwilr-component="quote-table-row"]');
                id = row.attr("data-qwilr-component-id");
                if (!value || !id)
                    return [2];
                numberValue = parseInt(value, 10);
                existingValue = lineItemQuantityMap[id];
                if (existingValue === numberValue)
                    return [2];
                lineItemQuantityMap[id] = numberValue;
                description = target
                    .parents('tr[data-qwilr-component="quote-table-row"]')
                    .find('div[data-qwilr-component="line-item-description"]')
                    .text();
                pageInfo = (0, Util_1.getPageTrackingInfo)(window);
                _a = (0, Util_1.blockDetailsForChildElement)(target), blockId = _a.blockId, blockName = _a.blockName, blockType = _a.blockType;
                if (!pageInfo || !blockId || !blockType || !blockName) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing blockType/blockName in page quote line item quantity click monitoring"),
                        source: "Quote line item quantity",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                            blockType: blockType,
                            blockName: blockName,
                        },
                    });
                    return [2];
                }
                interactionGroupId = RegisterInteractionListeners_1.blockIdInteractionGroupIdMap[blockId];
                if (!interactionGroupId)
                    return [2];
                lineItemQuantityUpdatedEvent = (0, Event_1.formatLineItemQuantityUpdatedEvent)({
                    pageInfo: pageInfo,
                    blockId: blockId,
                    quantity: numberValue,
                    description: description,
                    interactionGroupId: interactionGroupId,
                    lineItemId: id,
                    blockType: blockType,
                    blockName: blockName,
                });
                return [4, eventSender.send([lineItemQuantityUpdatedEvent])];
            case 1:
                _b.sent();
                return [3, 3];
            case 2:
                error_2 = _b.sent();
                (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                    error: error_2,
                    source: "Quote line item quantity",
                    description: "Quote line item quantity caught in try/catch",
                });
                return [3, 3];
            case 3: return [2];
        }
    });
}); };
var registerLineItemQuantityStepClick = function (eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    var upStepSelector, downStepSelector, upSteps, downSteps;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                upStepSelector = "button[data-qwilr-component=\"table-item-quantity-input-up-step\"]";
                downStepSelector = "button[data-qwilr-component=\"table-item-quantity-input-down-step\"]";
                return [4, (0, PollingElementSelector_1.pollingElementSelector)({ selector: upStepSelector })];
            case 1:
                upSteps = _a.sent();
                return [4, (0, PollingElementSelector_1.pollingElementSelector)({ selector: downStepSelector })];
            case 2:
                downSteps = _a.sent();
                if (!upSteps || !downSteps)
                    return [2];
                upSteps.on("click", function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, handleLineItemQuantityStepClick(event, eventSender, "up")];
                }); }); });
                downSteps.on("click", function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2, handleLineItemQuantityStepClick(event, eventSender, "down")];
                }); }); });
                return [2];
        }
    });
}); };
exports.registerLineItemQuantityStepClick = registerLineItemQuantityStepClick;
var handleLineItemQuantityStepClick = function (event, eventSender, direction) { return __awaiter(void 0, void 0, Promise, function () {
    var target, input, row, id, valueAttr, value, adjustedValue, existingValue, description, pageInfo, _a, blockId, blockName, blockType, interactionGroupId, lineItemQuantityUpdatedEvent, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                target = $(event.target);
                input = target
                    .parents('div[data-qwilr-component="plan-card-quantity-section"]')
                    .find('input[data-qwilr-component="table-item-quantity-input"]');
                row = target.parents('tr[data-qwilr-component="quote-table-row"]');
                id = row.attr("data-qwilr-component-id");
                valueAttr = input.attr("value");
                if (!id || !valueAttr)
                    return [2];
                value = parseInt(valueAttr, 10);
                if (direction === "down" && value === 1)
                    return [2];
                adjustedValue = direction === "up" ? value + 1 : value - 1;
                existingValue = lineItemQuantityMap[id];
                if (adjustedValue === existingValue)
                    return [2];
                description = target
                    .parents('tr[data-qwilr-component="quote-table-row"]')
                    .find('div[data-qwilr-component="line-item-description"]')
                    .text();
                lineItemQuantityMap[id] = adjustedValue;
                pageInfo = (0, Util_1.getPageTrackingInfo)(window);
                _a = (0, Util_1.blockDetailsForChildElement)(target), blockId = _a.blockId, blockName = _a.blockName, blockType = _a.blockType;
                if (!pageInfo || !blockId || !blockName || !blockType) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing blockType/blockName in page quote line item quantity click monitoring"),
                        source: "Quote line item quantity",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                            blockType: blockType,
                            blockName: blockName,
                        },
                    });
                    return [2];
                }
                interactionGroupId = RegisterInteractionListeners_1.blockIdInteractionGroupIdMap[blockId];
                if (!interactionGroupId)
                    return [2];
                lineItemQuantityUpdatedEvent = (0, Event_1.formatLineItemQuantityUpdatedEvent)({
                    pageInfo: pageInfo,
                    blockId: blockId,
                    quantity: adjustedValue,
                    description: description,
                    interactionGroupId: interactionGroupId,
                    lineItemId: id,
                    blockType: blockType,
                    blockName: blockName,
                });
                return [4, eventSender.send([lineItemQuantityUpdatedEvent])];
            case 1:
                _b.sent();
                return [3, 3];
            case 2:
                error_3 = _b.sent();
                (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                    error: error_3,
                    source: "Quote line item quantity",
                    description: "Quote line item quantity interaction caught in try/catch",
                });
                return [3, 3];
            case 3: return [2];
        }
    });
}); };
