"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatROICalculatorVariableChangeEvent = void 0;
var Types_1 = require("../../../Types");
var Types_2 = require("../../Types");
var HashWidgetId_1 = require("../../Utils/HashWidgetId");
var formatROICalculatorVariableChangeEvent = function (_a) {
    var pageInfo = _a.pageInfo, blockId = _a.blockId, term = _a.term, termValue = _a.termValue, roiCalculatorLabel = _a.roiCalculatorLabel, interactionGroupId = _a.interactionGroupId, blockName = _a.blockName, blockType = _a.blockType;
    var interactionName = Types_2.PageInteractionName.ROICalculatorUpdated;
    var interactionContentIdentifier = term;
    return {
        eventType: Types_1.EventType.PageInteraction,
        eventData: {
            blockId: blockId,
            viewId: pageInfo.pageViewId,
            pageId: pageInfo.pageId,
            accountId: pageInfo.accountId,
            fullName: pageInfo.fullName,
            viewUserId: pageInfo.viewUserId,
            interactionContentIdentifier: interactionContentIdentifier,
            interactionGroupId: interactionGroupId,
            widgetId: (0, HashWidgetId_1.hashWidgetId)({ interactionName: interactionName, blockId: blockId, interactionContentIdentifier: interactionContentIdentifier }),
            interactionType: "content",
            interactionMetadata: __assign(__assign({ interactionName: interactionName, term: term, termValue: termValue }, (roiCalculatorLabel && { roiCalculatorLabel: roiCalculatorLabel })), { blockName: blockName, blockType: blockType }),
        },
        timestamp: new Date().toISOString(),
    };
};
exports.formatROICalculatorVariableChangeEvent = formatROICalculatorVariableChangeEvent;
