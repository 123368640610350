// Generated by CoffeeScript 1.12.7
(function () {
  var FooterBadgeController, LogoColor;

  LogoColor = require("Common/Utils/ImageImports").LogoColor;

  module.exports = FooterBadgeController = (function () {
    function FooterBadgeController(createUi, partnerUrlSlug, badgeClickUrl) {
      var url;
      url =
        "//" +
        badgeClickUrl +
        "/?utm_source=qwilr-branding&utm_medium=qwilr-footer-partner-green&utm_campaign=create-your-own-qwilr";
      if (partnerUrlSlug) {
        url += "&ref=" + partnerUrlSlug;
      }
      this.ui = createUi(require("./FooterBadge.html"), {
        logoColor: LogoColor,
        url: url,
      });
    }

    return FooterBadgeController;
  })();
}.call(this));
