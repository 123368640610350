"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptForm = void 0;
var react_1 = __importStar(require("react"));
var AcceptFlow_1 = require("../../AcceptV2/AcceptFlow");
var Utils_1 = require("@CommonFrontend/AgreementView/Utils");
var AccepterList_1 = require("@CommonFrontend/AcceptButtonv2/AccepterList/AccepterList");
var styles = __importStar(require("./AcceptForm.css"));
var AcceptForm = function (_a) {
    var _b;
    var $timeout = _a.$timeout, onCloseModal = _a.onCloseModal, accepters = _a.accepters, acceptBlockSettings = _a.acceptBlockSettings, autofillEnabled = _a.autofillEnabled, customisation = _a.customisation, acceptedInCurrentSession = _a.acceptedInCurrentSession, requiresQuoteChoice = _a.requiresQuoteChoice;
    var settings = customisation ? (0, Utils_1.mergeAgreementOverrides)(acceptBlockSettings, customisation) : acceptBlockSettings;
    var initialScreen = "form";
    if (acceptedInCurrentSession()) {
        initialScreen = "accepterList";
    }
    else {
        initialScreen = accepters.number === ((_b = accepters.finalisedAccepters) === null || _b === void 0 ? void 0 : _b.length) ? "accepterList" : "form";
    }
    var _c = __read((0, react_1.useState)(initialScreen), 2), screen = _c[0], setScreen = _c[1];
    if (screen === "accepterList" && accepters.finalisedAccepters) {
        return (react_1.default.createElement("div", { className: styles.accepterListWrapper },
            react_1.default.createElement(AccepterList_1.AccepterList, { accepters: accepters.finalisedAccepters })));
    }
    return (react_1.default.createElement(AcceptFlow_1.PublicAcceptFlow, { isSimpleMode: true, onClose: function () {
            setScreen("accepterList");
        }, accepters: accepters, settings: settings, "$timeout": $timeout, autofillEnabled: autofillEnabled, autoScrollIntoView: false, requiresQuoteChoice: requiresQuoteChoice }));
};
exports.AcceptForm = AcceptForm;
