"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatLineItemQuantityUpdatedEvent = void 0;
var Types_1 = require("../../../../Types");
var Types_2 = require("../../../Types");
var HashWidgetId_1 = require("../../../Utils/HashWidgetId");
var formatLineItemQuantityUpdatedEvent = function (_a) {
    var pageInfo = _a.pageInfo, blockId = _a.blockId, quantity = _a.quantity, description = _a.description, interactionGroupId = _a.interactionGroupId, lineItemId = _a.lineItemId, blockType = _a.blockType, blockName = _a.blockName;
    var interactionName = Types_2.PageInteractionName.QuoteChanged;
    var interactionContentIdentifier = description;
    return {
        eventType: Types_1.EventType.PageInteraction,
        eventData: {
            blockId: blockId,
            viewId: pageInfo.pageViewId,
            pageId: pageInfo.pageId,
            accountId: pageInfo.accountId,
            fullName: pageInfo.fullName,
            viewUserId: pageInfo.viewUserId,
            interactionType: "pricingAndTransaction",
            interactionGroupId: interactionGroupId,
            interactionContentIdentifier: interactionContentIdentifier,
            widgetId: (0, HashWidgetId_1.hashWidgetId)({ interactionName: interactionName, blockId: blockId, interactionContentIdentifier: interactionContentIdentifier }),
            interactionMetadata: {
                interactionName: interactionName,
                nestedInteractionName: "Line Item Quantity Updated",
                value: quantity,
                description: description,
                lineItemId: lineItemId,
                blockType: blockType,
                blockName: blockName,
            },
        },
        timestamp: new Date().toISOString(),
    };
};
exports.formatLineItemQuantityUpdatedEvent = formatLineItemQuantityUpdatedEvent;
