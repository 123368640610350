"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageActivity = void 0;
var PageActivity = (function () {
    function PageActivity(tracker, plan, appEvents, environment, $log) {
        var _this = this;
        this.tracker = tracker;
        this.MIN_FOCUS_DURATION = 2000;
        this.HEARTBEAT_DURATIONS = [];
        this.MAX_BLOCKVIEW_DURATIONS = [];
        this.blockFocusStart = 0;
        this.blockFocusInterval = 0;
        this.focalBlock = null;
        this.blockViews = {};
        this.startTime = undefined;
        this.heartbeat = undefined;
        this.currentHeartbeatInterval = 0;
        this.currentHeartbeatIntervalLevel = 0;
        this.currentBlockViewIntervalLevel = 0;
        this.createHeartbeat = function (intervalSize) {
            _this.currentHeartbeatInterval = _this.HEARTBEAT_DURATIONS[intervalSize] * 1000;
            _this.heartbeat = window.setInterval(_this.triggerHeartbeat, _this.currentHeartbeatInterval);
        };
        this.createBlockCheckInterval = function () {
            var interval = _this.MAX_BLOCKVIEW_DURATIONS[0] * 1000;
            window.setInterval(_this.triggerBlockCheck, interval);
        };
        this.onLinkClick = function (link) {
            return _this.trackLinkClick(link);
        };
        this.onAcceptClick = function (quoteBlockId) {
            return _this.trackAcceptClick(quoteBlockId);
        };
        this.onAccept = function (quoteBlockId) {
            return _this.trackAccept(quoteBlockId);
        };
        this.triggerHeartbeat = function () {
            _this.tracker.trackHeartbeat({
                heartbeat_length: _this.currentHeartbeatInterval,
            });
            if (_this.shouldIncreaseHeartbeatLevel()) {
                window.clearInterval(_this.heartbeat);
                _this.currentHeartbeatIntervalLevel++;
                _this.createHeartbeat(_this.currentHeartbeatIntervalLevel);
            }
        };
        this.shouldIncreaseHeartbeatLevel = function () {
            return ((_this.hasBeenViewedAnHour(_this.startTime) && _this.currentHeartbeatIntervalLevel === 1) ||
                (_this.hasBeenViewedAMinute(_this.startTime) && _this.currentHeartbeatIntervalLevel === 0));
        };
        this.triggerBlockCheck = function () {
            if (_this.shouldSendEvent()) {
                _this.trackBlockView();
                _this.blockFocusInterval = Date.now();
                if (_this.shouldIncreaseBlockViewIntervalLevel()) {
                    _this.currentBlockViewIntervalLevel++;
                }
            }
        };
        this.shouldSendEvent = function () {
            if (_this.currentBlockViewIntervalLevel === 0) {
                return true;
            }
            var intervalViewTime = Date.now() - _this.blockFocusInterval;
            var threshold = _this.MAX_BLOCKVIEW_DURATIONS[_this.currentBlockViewIntervalLevel] * 1000;
            return intervalViewTime >= threshold - 100;
        };
        this.shouldIncreaseBlockViewIntervalLevel = function () {
            return ((_this.hasBeenViewedAnHour(_this.blockFocusStart) && _this.currentBlockViewIntervalLevel === 1) ||
                (_this.hasBeenViewedAMinute(_this.blockFocusStart) && _this.currentBlockViewIntervalLevel === 0));
        };
        this.getFocalBlock = function () {
            return _this.focalBlock;
        };
        this.setNewFocalBlock = function (block) {
            _this.focalBlock = block;
            _this.blockFocusStart = Date.now();
            _this.blockFocusInterval = Date.now();
            _this.currentBlockViewIntervalLevel = 0;
        };
        this.trackProjectOpen = function () {
            _this.tracker.trackProjectOpen();
        };
        this.trackLinkClick = function (event) {
            var link = $(event.currentTarget);
            var href = link.attr("href");
            var text = link.text();
            if (_this.environment === "dogfood") {
                _this.log.log("Track link click", { href: href, text: text });
            }
            return _this.tracker.trackLinkClick({
                link: {
                    href: href,
                    text: text,
                },
            });
        };
        this.trackBlockView = function (async) {
            if (async === void 0) { async = true; }
            var duration = Date.now() - _this.blockFocusInterval;
            if (duration < _this.MIN_FOCUS_DURATION) {
                return Promise.resolve();
            }
            var id = _this.getBlockId(_this.focalBlock);
            var title = _this.getBlockTitle(_this.focalBlock);
            if (_this.blockViews[id] == null) {
                _this.blockViews[id] = {
                    title: title,
                    duration: 0,
                };
            }
            _this.blockViews[id].duration += duration;
            if (_this.environment === "dogfood") {
                _this.log.log("Track block view", { id: id, title: title, duration: duration });
            }
            return _this.tracker.trackBlockView({
                block: {
                    id: id,
                    title: title,
                },
                view_duration: duration,
            }, { async: async });
        };
        this.trackAcceptClick = function (quoteBlockId) {
            if (_this.environment === "dogfood") {
                _this.log.log("Track accept click", { id: quoteBlockId });
            }
            return _this.tracker.trackAcceptClick({
                block: {
                    id: quoteBlockId,
                },
            });
        };
        this.trackAccept = function (quoteBlockId) {
            if (_this.environment === "dogfood") {
                _this.log.log("Track accept", { id: quoteBlockId });
            }
            return _this.tracker.trackAccept({
                block: {
                    id: quoteBlockId,
                },
            });
        };
        this.getBlockId = function (blockElement) {
            return blockElement === null || blockElement === void 0 ? void 0 : blockElement.attr("block-id");
        };
        this.getBlockTitle = function (blockElement) {
            var allNonEmptyTextElements = blockElement === null || blockElement === void 0 ? void 0 : blockElement.find("h1, h2, p").filter(function (i, elem) { return $(elem).text().trim(); });
            var titleElement = $(allNonEmptyTextElements).first();
            if (titleElement.length) {
                return titleElement.text().trim();
            }
            else {
                return "Untitled Block";
            }
        };
        this.hasBeenViewedAnHour = function (startTime) {
            return startTime != undefined && Date.now() > startTime + 60 * 60 * 1000;
        };
        this.hasBeenViewedAMinute = function (startTime) {
            return startTime != undefined && startTime + 60 * 1000 < Date.now();
        };
        this.environment = environment;
        this.trackProjectOpen();
        this.log = $log;
        this.startTime = Date.now();
        $(function () { return $('section[data-qwilr-component="block"]').on("click", "a", _this.onLinkClick); });
        appEvents.on("acceptClicked", function (event) {
            _this.onAcceptClick(event.quoteBlockId);
        });
        appEvents.on("accepted", function (event) {
            if (event === null || event === void 0 ? void 0 : event.quoteBlockId) {
                _this.onAccept(event.quoteBlockId);
            }
        });
        if (plan === "Starter") {
            return;
        }
        var createIntervalNoise = function (maxSize) { return Math.random() * (maxSize * 2) - maxSize; };
        this.HEARTBEAT_DURATIONS = [5 + createIntervalNoise(2), 60 + createIntervalNoise(5), 300 + createIntervalNoise(10)];
        this.MAX_BLOCKVIEW_DURATIONS = [
            5 + createIntervalNoise(2),
            60 + createIntervalNoise(5),
            300 + createIntervalNoise(10),
        ];
        this.createHeartbeat(0);
        this.createBlockCheckInterval();
    }
    return PageActivity;
}());
exports.PageActivity = PageActivity;
