"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jqueryLocal = require("jquery");
exports.default = (function () {
    var isSupported = function () {
        var f = "flex";
        var fw = "-webkit-".concat(f);
        var el = document.createElement("b");
        try {
            el.style.display = fw;
            el.style.display = f;
            return !!(el.style.display === f || el.style.display === fw);
        }
        catch (err) {
            return false;
        }
    };
    var handleNoFlexbox = function () {
        if (!isSupported()) {
            jqueryLocal("html").addClass("no-flexbox");
        }
    };
    return {
        isSupported: isSupported,
        handleNoFlexbox: handleNoFlexbox,
    };
});
