// Generated by CoffeeScript 1.12.7
(function () {
  var OverlayController;

  module.exports = OverlayController = (function () {
    function OverlayController(createUi) {
      this.ui = createUi(require("Common/Overlay/Overlay.html"), {
        visible: false,
        hideOnBackgroundClick: false,
        isCloseButtonVisible: true,
        onBackgroundClick: (function (_this) {
          return function () {
            if (_this.ui.hideOnBackgroundClick) {
              return _this.hide();
            }
          };
        })(this),
        hide: (function (_this) {
          return function () {
            return _this.hide();
          };
        })(this),
      });
      this.show = (function (_this) {
        return function (childUi, addClass, hideOnClick) {
          if (hideOnClick == null) {
            hideOnClick = false;
          }
          $("body").css("overflow", "hidden");
          _this.ui.visible = true;
          _this.ui.addClass = addClass || "";
          _this.ui.content = childUi;
          _this.ui.hideOnBackgroundClick = hideOnClick;
          return _this;
        };
      })(this);
      this.hide = (function (_this) {
        return function () {
          $("body").css("overflow", "auto");
          return (_this.ui.visible = false);
        };
      })(this);
      this.showCloseButton = (function (_this) {
        return function () {
          return (_this.ui.isCloseButtonVisible = true);
        };
      })(this);
      this.hideCloseButton = (function (_this) {
        return function () {
          return (_this.ui.isCloseButtonVisible = false);
        };
      })(this);
    }

    return OverlayController;
  })();
}.call(this));
