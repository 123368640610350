"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var events_1 = require("events");
var maker = function (constructor, extraArgs) {
    if (extraArgs === void 0) { extraArgs = {}; }
    return function (make) { return make(constructor, extraArgs); };
};
var invoker = function (func, extraArgs, context) {
    if (extraArgs === void 0) { extraArgs = {}; }
    if (context === void 0) { context = null; }
    return function (invoke) { return invoke(func, context, extraArgs); };
};
var getStringKey = function (obj, key) { return (obj === null || obj === void 0 ? void 0 : obj[key]) || null; };
module.exports = function ($rootScope) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
    var injectables = {
        mountReactComponent: require("MountReactComponent/MountReactComponent").default,
        environment: function () { var _a; return ((_a = $rootScope.initialData) === null || _a === void 0 ? void 0 : _a.environment) || "dogfood"; },
        hostname: (_a = $rootScope.initialData) === null || _a === void 0 ? void 0 : _a.hostname,
        badgeClickUrl: function (hostname) { return "".concat(hostname, "/welcome/start-creating"); },
        secretHash: (_b = $rootScope.initialData) === null || _b === void 0 ? void 0 : _b.secret,
        projectId: (_c = $rootScope.initialData) === null || _c === void 0 ? void 0 : _c.projectId,
        visitUrl: (_d = $rootScope.initialData) === null || _d === void 0 ? void 0 : _d.visitUrl,
        visitToken: ((_e = $rootScope.initialData) === null || _e === void 0 ? void 0 : _e.visitToken) || "",
        projectSecret: (_f = $rootScope.initialData) === null || _f === void 0 ? void 0 : _f.secret,
        isOwner: ((_g = $rootScope.initialData) === null || _g === void 0 ? void 0 : _g.isOwner) || false,
        isTemplateDomain: ((_h = $rootScope.initialData) === null || _h === void 0 ? void 0 : _h.isTemplateDomain) || false,
        partnerUrlSlug: ((_j = $rootScope.initialData) === null || _j === void 0 ? void 0 : _j.partnerUrlSlug) || "",
        appState: { badgeUnfolded: false },
        projectOwnerUserId: (_k = $rootScope.initialData) === null || _k === void 0 ? void 0 : _k.userId,
        projectOwnerUserEmail: (_l = $rootScope.initialData) === null || _l === void 0 ? void 0 : _l.userEmail,
        projectSpringboardSettings: (_m = $rootScope.initialData) === null || _m === void 0 ? void 0 : _m.springboardSettings,
        projectName: (_o = $rootScope.initialData) === null || _o === void 0 ? void 0 : _o.projectName,
        projectStatus: (_p = $rootScope.initialData) === null || _p === void 0 ? void 0 : _p.projectStatus,
        enableSocialBadge: ((_q = $rootScope.initialData) === null || _q === void 0 ? void 0 : _q.enableSocialBadge) || false,
        source: ((_r = $rootScope.initialData) === null || _r === void 0 ? void 0 : _r.source) || "",
        accountQuoteSettings: (_s = $rootScope.initialData) === null || _s === void 0 ? void 0 : _s.accountQuoteSettings,
        currencyLocaleProvider: { currencyLocale: (_t = $rootScope.initialData) === null || _t === void 0 ? void 0 : _t.currencyLocale },
        disableInteractives: (_u = $rootScope.initialData) === null || _u === void 0 ? void 0 : _u.disableInteractives,
        brandStore: {},
        blockStore: {},
        telemetry: {},
        variableService: maker(require("QuoteV2/Variables/VariableService").default),
        discountService: maker(require("@CommonFrontendBackend/Discount/Discount.service").default),
        labelService: maker(require("QuoteV2/Labels/LabelService").LabelService),
        billingScheduleOrchestrator: maker(require("QuoteV2/BillingSchedule/BillingScheduleOrchestrator").BillingScheduleOrchestrator),
        overlay: maker(require("Common/Overlay/Overlay")),
        overlayStack: maker(require("OverlayStack/OverlayStack.js")),
        OverlayStackCard: function () { return require("OverlayStack/OverlayCard/OverlayCard"); },
        imageLocation: function () { return require("Common/ImageLocation"); },
        projectCreatorProfile: invoker(require("Common/ProjectCreatorProfile")),
        badgeOpen: function (appState) { return function () { return appState.badgeUnfolded; }; },
        uniqueId: function () { return require("@CommonFrontendBackend/Utils/UniqueId").default; },
        utils: maker(require("Common/Utils")),
        noop: function () { return function () { return ({}); }; },
        projectTotal: maker(require("QuoteV2/ProjectTotal").default),
        quoteDataStore: maker(require("QuoteV2/QuoteDataStore").QuoteDataStore),
        projectCurrencies: maker(require("QuoteV2/ProjectCurrencies").default),
        acceptTelemetry: maker(require("AcceptV2/AcceptTelemetry").default),
        stripePublicKey: function () { return getStringKey($rootScope.initialData, "stripePublicKey"); },
        stripeUserId: function () { return getStringKey($rootScope.initialData, "stripeUserId"); },
        isStripeIntegrationEnabled: function () { return !!getStringKey($rootScope.initialData, "stripeUserId"); },
        analyticsEndpoint: (_v = $rootScope.initialData) === null || _v === void 0 ? void 0 : _v.analyticsEndpoint,
        tracker: maker(require("Analytics/Tracker").Tracker),
        googleAnalyticsIntegrationEnabled: (_w = $rootScope.initialData) === null || _w === void 0 ? void 0 : _w.googleAnalyticsIntegrationEnabled,
        mixPanelIntegrationEnabled: (_x = $rootScope.initialData) === null || _x === void 0 ? void 0 : _x.mixPanelIntegrationEnabled,
        heapAnalyticsIntegrationEnabled: (_y = $rootScope.initialData) === null || _y === void 0 ? void 0 : _y.heapAnalyticsIntegrationEnabled,
        publicApiBaseUrl: (_z = $rootScope.initialData) === null || _z === void 0 ? void 0 : _z.publicApiBaseUrl,
        clientUrl: (_0 = $rootScope.initialData) === null || _0 === void 0 ? void 0 : _0.clientUrl,
        clerkPublishableKey: (_1 = $rootScope.initialData) === null || _1 === void 0 ? void 0 : _1.clerkPublishableKey,
        authAppBaseUrl: (_2 = $rootScope.initialData) === null || _2 === void 0 ? void 0 : _2.authAppBaseUrl,
        springboardAppBaseUrl: (_3 = $rootScope.initialData) === null || _3 === void 0 ? void 0 : _3.springboardAppBaseUrl,
        consumerDataServiceBaseUrl: (_4 = $rootScope.initialData) === null || _4 === void 0 ? void 0 : _4.consumerDataServiceBaseUrl,
        appEvents: new events_1.EventEmitter(),
        userIconFactory: maker(require("UserIcon/UserIcon")),
        mobileBreakpoint: function (isPrintMode) { return (isPrintMode ? 0 : 750); },
        isPrintMode: invoker(require("Utils/IsPrintMode")),
        isPreviewMode: (_5 = $rootScope.initialData) === null || _5 === void 0 ? void 0 : _5.previewMode,
        isCollaboratorPage: (_6 = $rootScope.initialData) === null || _6 === void 0 ? void 0 : _6.isCollaboratorPage,
        isShowingNavbar: (_7 = $rootScope.initialData) === null || _7 === void 0 ? void 0 : _7.isShowingNavbar,
        isMobile: function (mobileBreakpoint) { return function () { return $(window).width() < mobileBreakpoint; }; },
        featureFlags: (_8 = $rootScope.initialData) === null || _8 === void 0 ? void 0 : _8.featureSet,
        flexboxChecker: require("../FlexboxChecker").default,
        plan: ((_9 = $rootScope.initialData) === null || _9 === void 0 ? void 0 : _9.plan) || "Starter",
        getFullName: function () { return require("@CommonFrontendBackend/Utils/GetFullName").default; },
        quoteBlocksList: maker(require("QuoteV2/QuoteBlocksList").QuoteBlocksList),
        manualTooltip: require("Utils/ManualTooltipTrigger"),
        hint: require("Tooltip/Hint/Hint").default,
        OnClickOutside: function () { return require("CommonUI/OnClickOutside/OnClickOutside").default; },
        Tooltip: function () { return require("Tooltip/Tooltip"); },
        Panel: require("Tooltip/Panel/Panel").default,
        speechBubble: require("Tooltip/SpeechBubble/SpeechBubble").default,
        Slider: function () { return require("CommonUI/Slider/Slider"); },
        concentricSpinner: invoker(require("CommonUI/ConcentricSpinner/ConcentricSpinner")),
        getPoweredByQwilr: invoker(require("CommonUI/PoweredByQwilr/PoweredByQwilr")),
        Button: require("CommonUI/Button/Button").default,
        AsyncButton: require("CommonUI/Button/AsyncButton").default,
        isTemplate: ((_10 = $rootScope.initialData) === null || _10 === void 0 ? void 0 : _10.isTemplate) || false,
        showSpringboardEngagementMenu: invoker(require("Common/Utils/showSpringboard")),
    };
    return Object.assign(injectables, require("@CommonFrontendBackend/FeatureFlags/FeatureSet"));
};
