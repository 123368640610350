"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageAnalyticsEventFactory = void 0;
var lodash_1 = require("lodash");
var Types_1 = require("./Types");
var PageAnalyticsEventFactory = (function () {
    function PageAnalyticsEventFactory() {
    }
    PageAnalyticsEventFactory.buildPageViewDurationEvent = function (pageInfo, duration, durationChange) {
        var _a, _b, _c;
        return {
            eventType: Types_1.EventType.PageDurationEvent,
            timestamp: new Date().toISOString(),
            eventData: {
                duration: duration,
                duration_change: durationChange,
                pageId: pageInfo.pageId,
                accountId: pageInfo.accountId,
                viewId: pageInfo.pageViewId,
                viewEmailAddress: pageInfo.identity,
                fullName: pageInfo.fullName,
                viewUserId: pageInfo.viewUserId || ((_c = (_b = (_a = window.analytics) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.call(_a).anonymousId()) !== null && _c !== void 0 ? _c : null),
                viewIdentityUnverified: pageInfo.viewIdentityUnverified,
            },
        };
    };
    PageAnalyticsEventFactory.buildBlockViewDurationEvent = function (pageInfo, blockTime) {
        var _a, _b, _c;
        return {
            eventType: Types_1.EventType.BlockViewDuration,
            timestamp: new Date().toISOString(),
            eventData: {
                duration: (0, lodash_1.round)(blockTime.durationMillis),
                duration_change: (0, lodash_1.round)(blockTime.durationChange),
                pageId: pageInfo.pageId,
                blockId: blockTime.blockId,
                blockName: blockTime.blockName,
                blockType: blockTime.blockType,
                accountId: pageInfo.accountId,
                viewId: pageInfo.pageViewId,
                viewEmailAddress: pageInfo.identity,
                fullName: pageInfo.fullName,
                viewUserId: pageInfo.viewUserId || ((_c = (_b = (_a = window.analytics) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.call(_a).anonymousId()) !== null && _c !== void 0 ? _c : null),
                viewIdentityUnverified: pageInfo.viewIdentityUnverified,
            },
        };
    };
    PageAnalyticsEventFactory.buildPageViwershipInteractionEventMessage = function (params) {
        var _a = params.pageInfo, accountId = _a.accountId, pageId = _a.pageId, pageViewId = _a.pageViewId, viewUserId = _a.viewUserId;
        return {
            eventType: Types_1.EventType.PageInteraction,
            timestamp: new Date().toISOString(),
            eventData: {
                accountId: accountId,
                pageId: pageId,
                viewId: pageViewId,
                viewUserId: viewUserId || "",
                interactionType: "content",
                blockId: params.blockId,
                interactionMetadata: {
                    linkText: params.linkText,
                    linkUrl: params.linkUrl,
                    interactionName: "Outbound Link Click",
                    blockType: params.blockType,
                    blockName: params.blockName,
                },
            },
        };
    };
    return PageAnalyticsEventFactory;
}());
exports.PageAnalyticsEventFactory = PageAnalyticsEventFactory;
