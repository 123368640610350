(function () {
  var FeatureFlag, expireIdentityVerification, initBoomerang;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  expireIdentityVerification = require("./IdentityVerificationExpirer").expireIdentityVerification;

  initBoomerang = require("./InitPublicBoomerang").initBoomerang;

  module.exports = function (
    di,
    $rootScope,
    make,
    scopeUi,
    appState,
    badgeOpen,
    overlay,
    overlayStack,
    projectCreatorProfile,
    isPrintMode,
    isPreviewMode,
    isCollaboratorPage,
    $timeout,
    usingFeature,
    environment,
    isOwner,
    projectName,
  ) {
    var badging,
      browserListener,
      extraSpace,
      formatPdfPreviewAfterPrintFormatter,
      objectFit,
      pageActivity,
      printformatter,
      ref,
      templateBanner,
      usingPdfBeta;
    make(require("Common/Keyboard/Keyboard"));
    if (environment === "production" && !isPrintMode) {
      require("@CommonFrontend/Utils/initSentry");
    }
    di.invoke(require("./ViewRecorder")["default"]);
    di.invoke(expireIdentityVerification);
    di.invoke(initBoomerang);
    usingPdfBeta = !usingFeature(FeatureFlag.OptOutNewEditor);
    if (isPrintMode) {
      printformatter = require("Common/PrintFormatter")["default"];
      di.invoke(printformatter, null, {
        usingPdfBeta: usingPdfBeta,
      });
    }
    if (isPreviewMode) {
      formatPdfPreviewAfterPrintFormatter = require("Common/PdfPreviewFomatter/PdfPreviewFomatter.ts")["default"];
      extraSpace = 1;
      formatPdfPreviewAfterPrintFormatter(
        (ref = $rootScope.initialData) != null ? ref.clientUrl : void 0,
        $("body").width() - extraSpace,
        usingPdfBeta,
      );
    }
    if (!isPrintMode) {
      pageActivity = make(require("Analytics/PageActivity").PageActivity);
      browserListener = new (require("BrowserListener/BrowserListener")["default"])(pageActivity);
    }
    badging = make(require("Badging/Badging"));
    scopeUi.badging = badging.ui;
    scopeUi.overlay = overlay.ui;
    scopeUi.overlayStack = overlayStack.ui;
    scopeUi.appState = appState;
    scopeUi.badgeOpen = badgeOpen;
    templateBanner = make(require("../TemplateBanner/TemplateBanner"));
    scopeUi.templateBanner = templateBanner.ui;
    objectFit = require("object-fit/dist/polyfill.object-fit.min.js");
    objectFit.polyfill({
      selector: "video",
      fittype: "cover",
      disableCrossDomain: "true",
    });
    $rootScope.di = di;
    if (!isPrintMode) {
      require("Analytics/InitAnalytics")["default"](usingFeature, $rootScope.initialData.hostname);
    }
    if (window.olark != null) {
      require("Integrations/Olark")();
    }
    di.invoke(require("Utils/FormFactorMonitor"));
    if (environment === "dogfood") {
      console.debug("Initial Data: ", $rootScope.initialData);
    }
    return di.invoke(require("QuoteChangePublisher")["default"]);
  };
}.call(this));
