// Generated by CoffeeScript 1.12.7

/*
  The controller for making all the badges on Qwilr projects
 */

(function () {
  var Badging;

  module.exports = Badging = (function () {
    function Badging(createUi, make, projectCreatorProfile, enableSocialBadge) {
      var badgeUis, badgeUisMap, badges;
      badges = [];
      if (!projectCreatorProfile.isEvangelist) {
        switch (projectCreatorProfile.branding) {
          case "standard":
            badges = ["CornerBadge", "ModestBadge"];
            break;
          case "humble":
            badges = ["ModestBadge"];
            break;
          case "white label":
          case "minimal":
            badges = [];
        }
      }
      if (projectCreatorProfile.isPartner) {
        badges = ["CornerBadge", "FooterBadge"];
      }
      if (enableSocialBadge) {
        badges.push("SocialBadge");
      }
      badgeUisMap = {
        CornerBadge: function () {
          return require("./CornerBadge/CornerBadge");
        },
        FooterBadge: function () {
          return require("./FooterBadge/FooterBadge");
        },
        ModestBadge: function () {
          return require("./ModestBadge/ModestBadge");
        },
        SocialBadge: function () {
          return require("./SocialBadge/SocialBadge");
        },
      };
      badgeUis = _.map(badges, function (badge) {
        return make(badgeUisMap[badge]());
      });
      this.ui = createUi(require("Badging/Badging.html"), {
        badges: badgeUis,
      });
    }

    return Badging;
  })();
}.call(this));
