// Generated by CoffeeScript 1.12.7
(function () {
  var MixPanelTracker;

  module.exports = MixPanelTracker = (function () {
    MixPanelTracker.prototype.log = null;

    MixPanelTracker.prototype.client = null;

    function MixPanelTracker($log, noop) {
      var ref, ref1, ref2, ref3, visit;
      this.log = $log;
      visit = {};
      if (
        ((ref = window.qSessionTracking) != null ? ((ref1 = ref.visit) != null ? ref1.identity : void 0) : void 0) !=
        null
      ) {
        visit.userId = window.qSessionTracking.visit.identity;
      }
      if (
        ((ref2 = window.qSessionTracking) != null ? ((ref3 = ref2.visit) != null ? ref3.token : void 0) : void 0) !=
        null
      ) {
        visit.visitToken = window.qSessionTracking.visit.token;
      }
      mixpanel.register(visit);
      this.trackProjectOpen = (function (_this) {
        return function (event) {
          var ref4;
          if (event == null) {
            event = {};
          }
          return _this.track("Project opened", {
            projectId: (ref4 = window.qSessionTracking) != null ? ref4.projectId : void 0,
          });
        };
      })(this);
      this.trackLinkClick = (function (_this) {
        return function (event) {
          var ref4;
          if (event == null) {
            event = {};
          }
          return _this.track("Link clicked", {
            link: (ref4 = event.link) != null ? ref4.href : void 0,
          });
        };
      })(this);
      this.trackBlockView = noop;
      this.trackHeartbeat = noop;
      this.trackProjectClose = noop;
      this.trackAcceptClick = (function (_this) {
        return function (event) {
          var ref4;
          if (event == null) {
            event = {};
          }
          return _this.track("Accept button clicked", {
            quoteBlockId: (ref4 = event.block) != null ? ref4.id : void 0,
          });
        };
      })(this);
      this.trackAccept = (function (_this) {
        return function (event) {
          var ref4;
          if (event == null) {
            event = {};
          }
          return _this.track("Quote accepted", {
            quoteBlockId: (ref4 = event.block) != null ? ref4.id : void 0,
          });
        };
      })(this);
      this.track = (function (_this) {
        return function (eventName, options) {
          return mixpanel.track(eventName, options);
        };
      })(this);
    }

    return MixPanelTracker;
  })();
}.call(this));
