/*
  Print Formatter
  This module performs DOM modifications to the page to prepare it for printing.

  Firstly, why all this DOM manipulation? The problem is that webpages are seamless,
  they have no boundaries or set dimensions, but PDF and print does have explicit dimensions.
  So we need an intelligent way of splitting up things without boundaries into things with
  boundaries, without getting aesthetically unpleasing results i.e. elements split across pages.

  For blocks whose content can get long and spill over multiple pages the strategy is to unpack its DOM
  elements into an essentially linear list, and then divide that list across "virtual pages". These
  pages are 1-to-1 with the pages of the PDF output.
*/

import {
  REMOVE_EMBEDDED_CONTENT,
  REMOVE_PAGE_NUMBERS,
  REMOVE_VIDEOS,
} from "@CommonFrontendBackend/PdfPreviewConfig/PreviewExportConfig";

const getParameterByName = (name) => {
  const url = window.location.href;
  const queryName = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + queryName + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

const isQueryParamTrue = (queryName) => {
  const queryValue = getParameterByName(queryName);
  return queryValue === "true" || queryValue === "1";
};

const removeBlocksByClassName = (className) => {
  $(className).each((index, element) => {
    $(element).remove();
  });
};

const removeEmbeddedContentIfSet = () => {
  if (isQueryParamTrue(REMOVE_EMBEDDED_CONTENT)) {
    removeBlocksByClassName(".source-module");
    removeBlocksByClassName(".embedded-content");
  }
};

const removeVideosIfSet = () => {
  if (isQueryParamTrue(REMOVE_VIDEOS)) {
    removeBlocksByClassName(".video-module");
    removeBlocksByClassName(".video-content");
  }
};

const getFirstNonEmptyElement = (page, selector) => {
  const allElements = $(page).find(selector);
  return $.grep(allElements, (el) => $(el).text() !== "");
};

const applyPageNumberColor = (pageNumber, color, opacity) => {
  pageNumber.css("color", color);
  pageNumber.css("opacity", opacity);
};

// for block with paragraphs, make page number the first paragraph color
// otherwise rgba(34, 34, 34,0.5)
// for text and splash block, use either non empty h1 or h2 or paragraph color
const addPageNumbersIfSet = () => {
  if (isQueryParamTrue(REMOVE_PAGE_NUMBERS)) return;
  {
    $(".print-virtual-page")
      .get()
      .forEach((page, index) => {
        $(page).append(`<div class="page-number">${index + 1}</div>`);

        const pageNumber = $(page).find(".page-number");

        applyPageNumberColor(pageNumber, "rgb(34, 34, 34)", 0.5);

        const firstNonEmptyParagraph = getFirstNonEmptyElement(page, "p");
        if (firstNonEmptyParagraph.length > 0) {
          applyPageNumberColor(pageNumber, $(firstNonEmptyParagraph).css("color"), 1);
        } else {
          const parentClasses = $(page).parent().prop("className");
          if (parentClasses.includes("text-block") || parentClasses.includes("splash-module")) {
            const firstNonEmptyElement = getFirstNonEmptyElement(page, "h1,h2,p");
            if (firstNonEmptyElement.length > 0) {
              applyPageNumberColor(pageNumber, $(firstNonEmptyElement).css("color"), 1);
            }
          }
        }
      });
  }
};

export default function PrintFormatter(invoke, usingPdfBeta) {
  $(document.body).removeAttr("id").removeClass("print").addClass("print-legacy");

  if (usingPdfBeta) {
    $(document.body).addClass("pdf-beta");
  }

  // PhantomJS has the quirk of including extra blank pages
  // when content is near to filling the page size, so we
  // shave off 1px of DOM content to prevent that from occuring.
  var SHAVE_PAGE_HEIGHT = 1;

  // Headless Chromium needs 2 pixels shaved for the same reason
  // (PhantomJS does not seem to implement string.includes(), hence the existential check first)
  if (window.navigator.userAgent.includes && window.navigator.userAgent.includes("HeadlessChrome")) {
    SHAVE_PAGE_HEIGHT = 2;
  }

  var PAGE_HEIGHT = $("body").height() - SHAVE_PAGE_HEIGHT;
  var divideElemsAcross = require("./DivideAcross");
  var allLoaded = invoke(require("./AllLoaded"));

  // Init.
  allLoaded(function () {
    // ensure there are no images that are taller than a page
    $("figure img")
      .css("width", "")
      .css("max-height", PAGE_HEIGHT * 0.7);

    // Process the DOM for each block.
    $(".project-block").get().forEach(processBlock);

    // Set all virtual page containers to the specified page height.
    $(".print-virtual-page:not(.too-tall-child)").css("height", PAGE_HEIGHT);

    removeEmbeddedContentIfSet();
    removeVideosIfSet();
    addPageNumbersIfSet();
  });

  function processTextOrAcceptBlock(block, isTextBlock) {
    var textElems = $(block).find(".row > .columns > *").get();
    divideElemsAcross({
      rootElem: block,
      contentElems: textElems,
      HEIGHT: PAGE_HEIGHT,
    });

    // Get rid of of the emptied container DOM elements.
    $(block).find(".row").remove();

    if (isTextBlock) {
      centerSmallTextBlocks(block);
    }
  }

  /*
    For those blocks that only have a little bit of text
    it looks strange having a big blank page, so
    lets center the text. NOTE: We don't do this for virtual pages
    with a small amount of text, just for actual blocks.
  */

  // The fraction of the page height below which we do the centering.
  var CENTERING_THRESHOLD = 0.275;

  function centerSmallTextBlocks(block) {
    if ($(block).find(".print-virtual-page").length === 1) {
      var page = $(block).find(".print-virtual-page").eq(0);
      var heightOfContent = 0;
      page.children().each(function () {
        heightOfContent += $(this).outerHeight();
      });
      if (heightOfContent < CENTERING_THRESHOLD * PAGE_HEIGHT) {
        $(block).addClass("print-center-content");
        page.children().wrapAll("<div class='content-wrapper'></div>");
      }
    }
  }

  function processQuoteBlock(block) {
    var quoteWrapper;
    var sectionElems;

    // @TODO: When new quote block is live for all users in prod
    // remove this logic and just use the new quote branch.
    var oldQuote = $(block).find(".row").hasClass("old-quote");
    if (!oldQuote) {
      processInteractiveQuotes(block);
      quoteWrapper = $("<div></div>").addClass("qwilr-quote").appendTo(block);
      sectionElems = $(block).find(".quote-header, .qwilr-quote > *").get();
    } else {
      quoteWrapper = $("<div></div>").addClass("old-quote").appendTo(block);
      sectionElems = $(block).find(".quote-header, .quote-section, .totals").get();
    }

    divideElemsAcross({
      rootElem: quoteWrapper,
      contentElems: sectionElems,
      HEIGHT: PAGE_HEIGHT,
    });

    // Get rid of of the emptied container DOM elements.
    $(block).find(".row").remove();
  }

  function processAuditTrailBlock(block) {
    var tableWrapper;
    var sectionElems;

    tableWrapper = $("<div></div>").appendTo(block);
    sectionElems = $(block).find("#audit-heading, #accepter-details, #project-details").get();

    divideElemsAcross({
      rootElem: tableWrapper,
      contentElems: sectionElems,
      HEIGHT: PAGE_HEIGHT,
    });
  }

  function processQuoteBlockV2(block) {
    var quoteWrapper;
    var sectionElems;

    processInteractiveQuotes(block);
    quoteWrapper = $("<div></div>").addClass("qwilr-quote-v2").appendTo(block);
    sectionElems = $(block).find(".quote-header, .qwilr-quote-v2 > *").get();

    divideElemsAcross({
      rootElem: quoteWrapper,
      contentElems: sectionElems,
      HEIGHT: PAGE_HEIGHT,
    });

    // Get rid of of the emptied container DOM elements.
    $(block).find(".row").remove();
  }

  // Interactive quotes in a print context don't make much sense.
  // So we replace interactive form elements with plain text equivalents.
  function processInteractiveQuotes(block) {
    // Replace any number inputs with their values as text.
    $(block)
      .find("input[type=number]")
      .each(function () {
        var input = $(this);
        var val = input.val();
        var parentTd = input.parent();
        input.remove();
        parentTd.prepend("<span>" + val + "</span>");
      });
  }

  // When a Splash block has enough content to make it taller
  // than the defined page height, we need to divide the content
  // across duplicated copies of the block, so that all the "pages"
  // have the same image background and tint etc.
  function processSplashBlock(block) {
    var splashContent = $(block).find(".row .content").children().get();
    divideElemsAcross({
      rootElem: $(block),
      contentElems: splashContent,
      HEIGHT: PAGE_HEIGHT,
    });

    // Now we need to copy in the Splash image DOM content
    // for each virtual page and wrap it in the container elems
    // to mirror whatever styles are defined for that element.
    var imageElems = $(block).find(".image, .tint-v2");
    var contentContainerElem = $(block).find(".container");
    $(block)
      .find(".print-virtual-page")
      .each(function () {
        $(this).addClass("fill-block");
        $(this).contents().wrapAll(contentContainerElem);
        imageElems.clone().appendTo($(this));
      });

    // Finally, remove the empty container element.
    $(block).find(" > .fill-block").not(".print-virtual-page").remove();
  }

  function wrapInVirtualPage(block) {
    $(block).children().wrapAll("<div class='print-virtual-page'></div>");
  }

  /*
    Empty placeholder elements don't look too bad on the web,
    but in a print context they can make page spacing look very strange.
    So lets remove any empty placeholder elements from the DOM.

    @TODO: this check would not be necessary if the text editor correctly
    removed "placeholder" attributes when text is entered. We should remove
    this function once the text editor works reliably in that regard,
    AND old content has been migrated to have no empty placeholders in text.
  */
  function removeEmptyPlaceholders(block) {
    $(block)
      .find("[placeholder=true]")
      .each(function () {
        if ($(this).text() === "") {
          $(this).remove();
        }
      });
  }

  function processVideoOrSourceBlock(block) {
    // We format video and source blocks in basically the same way as text blocks
    var topLevelElements = $(block).find(".row").children().get();

    divideElemsAcross({
      rootElem: block,
      contentElems: topLevelElements,
      HEIGHT: PAGE_HEIGHT,
    });

    $(block).find(".row").remove();
  }

  function processBlock(block) {
    removeEmptyPlaceholders(block);

    if ($(block).hasClass("text-block")) {
      processTextOrAcceptBlock(block, true);
    } else if ($(block).hasClass("quote-module")) {
      processQuoteBlock(block);
    } else if ($(block).hasClass("quote-v2-module")) {
      processQuoteBlockV2(block);
    } else if ($(block).hasClass("accept-module")) {
      processTextOrAcceptBlock(block, false);
    } else if ($(block).hasClass("video-module") || $(block).hasClass("source-module")) {
      processVideoOrSourceBlock(block);
    } else if ($(block).hasClass("source-module")) {
      wrapInVirtualPage(block);
    } else if ($(block).hasClass("splash-module")) {
      processSplashBlock(block);
    } else if ($(block).hasClass("audit-trail")) {
      processAuditTrailBlock(block);
    }
  }
}
