// Generated by CoffeeScript 1.12.7
(function () {
  var Bootstrapper;

  module.exports = Bootstrapper = (function () {
    function Bootstrapper(di, scopeUi) {
      var initiator, injectables, wiringConfig;
      initiator = require("./Initiator");
      wiringConfig = require("./Wiring");
      injectables = di.invoke(wiringConfig);
      di.load(injectables);
      di.invoke(initiator, null, {
        scopeUi: scopeUi,
      });
    }

    return Bootstrapper;
  })();
}.call(this));
