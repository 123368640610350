"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var hydrationHelpers_1 = require("Public/Widgets/hydrationHelpers");
exports.default = angular.module("qwilr.mountWidgetsDirective", []).directive("mountWidgets", function ($rootScope) {
    return {
        restrict: "A",
        link: function ($scope, elem, attrs) {
            var root = elem.get(0);
            var unmountHydratedWidgets = (0, hydrationHelpers_1.hydrate)(root, "[data-hydration-host]", { di: $rootScope.di, $scope: $scope });
            elem.on("$destroy", function () {
                unmountHydratedWidgets();
            });
        },
        priority: 1001,
    };
});
