"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
var Adapters_1 = require("./Adapters");
var PreviewConfig_1 = require("@CommonFrontendBackend/PdfPreviewConfig/PreviewConfig");
var lodash_1 = require("lodash");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var isBranchPlacedNode = function (node) { return !!node.children; };
var isLeafPlacedNode = function (node) { return !node.children; };
var isPageNode = function (node) { return !node.parent; };
var SHAVE_HEIGHT_MAP = (_a = {},
    _a[PreviewConfig_1.Orientation.Landscape] = (_b = {},
        _b[PreviewConfig_1.PageSize.A4] = 1,
        _b[PreviewConfig_1.PageSize.USPaper] = 0.2,
        _b),
    _a[PreviewConfig_1.Orientation.Portrait] = (_c = {},
        _c[PreviewConfig_1.PageSize.A4] = 2,
        _c[PreviewConfig_1.PageSize.USPaper] = 0.2,
        _c),
    _a);
var PageFullError = (function (_super) {
    __extends(PageFullError, _super);
    function PageFullError(continuationRenderNode, rerenderPath) {
        var _this = _super.call(this) || this;
        _this.continuationRenderNode = continuationRenderNode;
        _this.rerenderPath = rerenderPath;
        Object.setPrototypeOf(_this, PageFullError.prototype);
        return _this;
    }
    return PageFullError;
}(Error));
var mergeErrors = function (errors, path) {
    var baseErrorIndex = errors.findIndex(function (e) { return !!e; });
    if (baseErrorIndex === -1) {
        return;
    }
    var getNode = function (renderNode) {
        return path.map(function (p) { return p[0]; }).reduce(function (prev, curr) { return prev.children[curr][0]; }, renderNode);
    };
    var baseError = errors[baseErrorIndex];
    var baseNode = getNode(baseError.continuationRenderNode);
    for (var branchIndex = 0; branchIndex < errors.length; branchIndex++) {
        var err = errors[branchIndex];
        if (err) {
            var branchNode = getNode(err.continuationRenderNode);
            if (branchNode.children[branchIndex]) {
                baseNode.children[branchIndex] = branchNode.children[branchIndex];
                branchNode.children[branchIndex].forEach(function (node) { return (node.parent = baseNode); });
            }
            else {
                baseNode.children[branchIndex] = [];
            }
        }
        else {
            baseNode.children[branchIndex] = [];
        }
    }
    return new PageFullError(baseError.continuationRenderNode, (0, lodash_1.initial)(baseError.rerenderPath));
};
var Formatter = (function () {
    function Formatter(root, rawBlocks, options) {
        var _this = this;
        this.root = root;
        this.rawBlocks = rawBlocks;
        this.options = options;
        this.pages = [];
        this.createPlaceableElement = function (node, branchIndex) {
            var _a;
            var el = node.element.cloneNode(true);
            var currentPage = (0, lodash_1.last)(_this.pages);
            var previousBlock = (0, lodash_1.last)(currentPage.children);
            if ((0, Adapters_1.isRootNode)(node) && !!previousBlock) {
                if (previousBlock.renderNode.backgroundColor === node.backgroundColor) {
                    el.style.paddingTop = "0";
                    el.style.boxShadow = "0 -1px 0 0 ".concat(node.backgroundColor);
                }
            }
            if (!(0, Adapters_1.isRootNode)(node) && (0, Adapters_1.isRootNode)(node.parent)) {
                var isLastNode = (0, lodash_1.last)(node.parent.children[branchIndex]) === node;
                var isTextTag = [Adapters_1.HTMLTag.H1, Adapters_1.HTMLTag.H2, Adapters_1.HTMLTag.P].includes(Adapters_1.HTMLTag[el.tagName]);
                if (isLastNode && isTextTag) {
                    el.style.paddingBottom = "0";
                }
            }
            if (!(0, Adapters_1.isRootNode)(node) && ((_a = el.classList) === null || _a === void 0 ? void 0 : _a.contains("side-by-side"))) {
                if (node.parent.children[branchIndex][0] === node) {
                    el.style.marginTop = "0";
                }
                if ((0, lodash_1.last)(node.parent.children[branchIndex]) === node) {
                    el.style.marginBottom = "0";
                }
            }
            if (node.split) {
                el.classList.add("split");
            }
            return el;
        };
        this.placeElement = function (node, insertionNode, renderPath) { return __awaiter(_this, void 0, Promise, function () {
            var branchIndex, element, slots, renderedNode, isOnInitialRenderPath, currentPageIndex, currentPage, isOversizedElement, _a, continuation, path;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        branchIndex = renderPath.length > 0 ? (0, lodash_1.last)(renderPath)[0] : 0;
                        element = this.createPlaceableElement(node, branchIndex);
                        slots = element.querySelectorAll ? Array.from(element.querySelectorAll("slot")) : [];
                        if (slots.length > 0) {
                            renderedNode = {
                                element: element,
                                slots: slots,
                                parent: insertionNode,
                                renderNode: node,
                                children: [],
                            };
                        }
                        else {
                            renderedNode = { element: element, parent: insertionNode, renderNode: node };
                        }
                        insertionNode.slots[branchIndex].before(element);
                        if (isPageNode(insertionNode)) {
                            insertionNode.children.push(renderedNode);
                        }
                        else if (insertionNode.children[branchIndex]) {
                            insertionNode.children[branchIndex].push(renderedNode);
                        }
                        else {
                            insertionNode.children[branchIndex] = [renderedNode];
                        }
                        return [4, this.waitForElementToLoad(element)];
                    case 1:
                        _b.sent();
                        isOnInitialRenderPath = renderPath.reduce(function (prev, curr) { return prev && curr[1] === 0; }, true);
                        currentPageIndex = this.pages.length - 1;
                        currentPage = this.pages[currentPageIndex];
                        isOversizedElement = currentPage.children.length === 1 && isOnInitialRenderPath;
                        if (this.currentPageContentHeight > Math.ceil(this.pageHeight) && !isOversizedElement) {
                            _a = this.cleanPartialRender(renderedNode, renderPath), continuation = _a.continuation, path = _a.path;
                            throw new PageFullError(continuation, path);
                        }
                        return [2, renderedNode];
                }
            });
        }); };
        this.waitForElementToLoad = function (element) { return __awaiter(_this, void 0, Promise, function () {
            var maxImageLoadTime, images;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (element.nodeType === Adapters_1.NodeType.Text) {
                            return [2];
                        }
                        maxImageLoadTime = 15000;
                        images = Array.from(element.querySelectorAll("img"));
                        return [4, Promise.all(images.map(function (image) {
                                return Promise.race([
                                    new Promise(function (resolve) {
                                        if (image.getAttribute("src") !== "") {
                                            image.onload = function () {
                                                resolve(null);
                                            };
                                        }
                                        else {
                                            resolve(null);
                                        }
                                    }),
                                    (0, CommonUtils_1.delay)(maxImageLoadTime),
                                ]);
                            }))];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        this.cleanPartialRender = function (failedNode, renderPath) {
            var e_1, _a;
            var _b, _c;
            var node = failedNode;
            var rerenderPath = [];
            var pathIndex = renderPath.length;
            var isOnlyBlockOnPage = (0, lodash_1.last)(_this.pages).children.length === 1;
            var allNodesAreOrphanable = function (node, path) {
                var parent = node.parent;
                if (isPageNode(parent)) {
                    return true;
                }
                var siblings = (0, lodash_1.initial)(parent.children[(0, lodash_1.last)(path)[0]]);
                var siblingsAreOrphanable = siblings
                    .map(function (child) { return child.renderNode.orphanable; })
                    .reduce(function (acc, orphanable) { return acc && orphanable; }, true);
                return !!siblingsAreOrphanable && allNodesAreOrphanable(parent, (0, lodash_1.initial)(path));
            };
            var isUnavoidableOrphan = isOnlyBlockOnPage && allNodesAreOrphanable(failedNode, renderPath);
            while (true) {
                pathIndex--;
                var parentNode = node.parent;
                (_c = (_b = node.element) === null || _b === void 0 ? void 0 : _b.classList) === null || _c === void 0 ? void 0 : _c.add("continued");
                if (isPageNode(parentNode)) {
                    var isBranchEmpty = isLeafPlacedNode(node) ||
                        node.children.length === 0 ||
                        node.children[renderPath[0][0]].length === 0;
                    if (isBranchEmpty) {
                        var _d = __read(parentNode.children.splice(parentNode.children.length - 1), 1), removedChild = _d[0];
                        removedChild.element.remove();
                    }
                    else {
                        node.element.classList.add("continued");
                    }
                    break;
                }
                var _e = __read(renderPath[pathIndex], 2), branchIndex = _e[0], nodeIndex = _e[1];
                var shouldRemove = isLeafPlacedNode(node) ||
                    (isBranchPlacedNode(node) &&
                        (node === failedNode || node.children[renderPath[pathIndex + 1][0]].length === 0));
                if (shouldRemove) {
                    while (nodeIndex >= 0) {
                        var isPreviousNodeOrphanable = nodeIndex !== 0 &&
                            parentNode.children[branchIndex][nodeIndex - 1].renderNode
                                .orphanable;
                        var removingOrphans = !isUnavoidableOrphan && isPreviousNodeOrphanable;
                        if (removingOrphans) {
                            nodeIndex -= 1;
                        }
                        else {
                            break;
                        }
                    }
                    rerenderPath = [[branchIndex, nodeIndex]];
                    var removedChildren = parentNode.children[branchIndex].splice(nodeIndex);
                    try {
                        for (var removedChildren_1 = (e_1 = void 0, __values(removedChildren)), removedChildren_1_1 = removedChildren_1.next(); !removedChildren_1_1.done; removedChildren_1_1 = removedChildren_1.next()) {
                            var removedChild = removedChildren_1_1.value;
                            removedChild.element.remove();
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (removedChildren_1_1 && !removedChildren_1_1.done && (_a = removedChildren_1.return)) _a.call(removedChildren_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                }
                if (!shouldRemove && !(0, Adapters_1.isRootNode)(node.renderNode)) {
                    rerenderPath = __spreadArray([[branchIndex, nodeIndex]], __read(rerenderPath), false);
                }
                node = parentNode;
            }
            var continuation = (0, Adapters_1.partialCloneNode)(node.renderNode, rerenderPath);
            return { continuation: continuation, path: rerenderPath };
        };
        this.completePage = function () {
            var lastPageEmpty = _this.pages.length !== 0 && (0, lodash_1.last)(_this.pages).children.length === 0;
            if (lastPageEmpty) {
                return;
            }
            var currentPageIndex = _this.pages.length - 1;
            var currentPage = _this.pages[currentPageIndex];
            var lastBlock = (0, lodash_1.last)(currentPage.children);
            $(lastBlock.element).css("flex-grow", 1);
            if (!_this.options.removePageNumbers) {
                var pageNumber = $("<div class=\"page-number\">".concat(currentPageIndex + 1, "</div>"));
                pageNumber.css("color", lastBlock.renderNode.foregroundColor);
                currentPage.element.appendChild(pageNumber.get(0));
            }
        };
        var _a = this.options, pageSize = _a.pageSize, removeEmbeddedContent = _a.removeEmbeddedContent, removeVideos = _a.removeVideos, orientation = _a.orientation, isAgreementView = _a.isAgreementView;
        this._pageHeight = window.innerHeight - SHAVE_HEIGHT_MAP[orientation][pageSize];
        this.blockInstances = (0, Adapters_1.createBlocks)(this.rawBlocks, {
            pageHeight: this.pageHeight,
            removeEmbeddedContent: removeEmbeddedContent,
            removeVideos: removeVideos,
            isAgreementView: isAgreementView,
        });
    }
    Formatter.prototype.createPage = function () {
        var lastPageEmpty = this.pages.length !== 0 && (0, lodash_1.last)(this.pages).children.length === 0;
        if (lastPageEmpty) {
            return;
        }
        var page = $('<div class="print-virtual-page"><slot /></div>').height(this.pageHeight).get(0);
        if (this.pageInsertionPoint == undefined) {
            throw new Error("Calling createPage before render when formatting for print");
        }
        if (!page)
            throw new Error("JQuery Selector for 'page' is undefined");
        this.pageInsertionPoint.before(page);
        this.pages.push({
            element: page,
            slots: Array.from(page.querySelectorAll("slot")),
            children: [],
        });
    };
    Object.defineProperty(Formatter.prototype, "pageHeight", {
        get: function () {
            return this._pageHeight;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Formatter.prototype, "currentPageContentHeight", {
        get: function () {
            var currentPageHeight = (0, lodash_1.last)(this.pages).children
                .map(function (child) { return $(child.element).outerHeight(); })
                .reduce(function (prev, curr) { return prev + curr; }, 0);
            return currentPageHeight;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Formatter.prototype, "currentPageLeafNodeCount", {
        get: function () {
            var leafNodeCount = function (nodes) {
                return nodes.reduce(function (count, node) {
                    return count +
                        (isBranchPlacedNode(node)
                            ? node.children.reduce(function (innerCount, branch) { return innerCount + leafNodeCount(branch); }, 0)
                            : 1);
                }, 0);
            };
            return leafNodeCount((0, lodash_1.last)(this.pages).children);
        },
        enumerable: false,
        configurable: true
    });
    Formatter.prototype.recursiveRender = function (node_1, insertionNode_1) {
        return __awaiter(this, arguments, Promise, function (node, insertionNode, renderPath) {
            var placedNode, branchErrors, branchIndex, branch, childIndex, child, err_1, mergedError;
            if (renderPath === void 0) { renderPath = []; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.placeElement(node, insertionNode, renderPath)];
                    case 1:
                        placedNode = _a.sent();
                        branchErrors = [];
                        branchIndex = 0;
                        _a.label = 2;
                    case 2:
                        if (!(branchIndex < node.children.length)) return [3, 10];
                        branch = node.children[branchIndex];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 8, , 9]);
                        childIndex = 0;
                        _a.label = 4;
                    case 4:
                        if (!(childIndex < branch.length)) return [3, 7];
                        child = branch[childIndex];
                        return [4, this.recursiveRender(child, placedNode, __spreadArray(__spreadArray([], __read(renderPath), false), [[branchIndex, childIndex]], false))];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        childIndex++;
                        return [3, 4];
                    case 7:
                        branchErrors.push(undefined);
                        return [3, 9];
                    case 8:
                        err_1 = _a.sent();
                        if (err_1 instanceof PageFullError) {
                            if (renderPath.length >= err_1.rerenderPath.length) {
                                throw err_1;
                            }
                            branchErrors.push(err_1);
                        }
                        else {
                            throw err_1;
                        }
                        return [3, 9];
                    case 9:
                        branchIndex++;
                        return [3, 2];
                    case 10:
                        mergedError = mergeErrors(branchErrors, renderPath);
                        if (mergedError) {
                            throw mergedError;
                        }
                        return [2, placedNode];
                }
            });
        });
    };
    Formatter.prototype.render = function () {
        return __awaiter(this, void 0, Promise, function () {
            var newPagePerBlock, _a, _b, block, startNewPageBefore, startNewPageAfter, type, blockId, currentRenderNode, err_2, e_2_1;
            var e_2, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        newPagePerBlock = !this.options.compactLayout;
                        this.pageInsertionPoint = document.createElement("slot");
                        this.root.prepend(this.pageInsertionPoint);
                        if (!newPagePerBlock)
                            this.createPage();
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 11, 12, 13]);
                        _a = __values(this.blockInstances), _b = _a.next();
                        _d.label = 2;
                    case 2:
                        if (!!_b.done) return [3, 10];
                        block = _b.value;
                        startNewPageBefore = block.startNewPageBefore, startNewPageAfter = block.startNewPageAfter, type = block.type, blockId = block.blockId;
                        currentRenderNode = void 0;
                        try {
                            currentRenderNode = block.tree;
                        }
                        catch (err) {
                            console.error("Error encountered while parsing blockId: ".concat(blockId, " (type: ").concat(type, ")"));
                            if (err instanceof Error)
                                console.error(err.message, err.stack);
                            throw err;
                        }
                        if (startNewPageBefore && block !== (0, lodash_1.first)(this.blockInstances))
                            this.completePage();
                        if (newPagePerBlock || startNewPageBefore)
                            this.createPage();
                        _d.label = 3;
                    case 3:
                        if (!true) return [3, 8];
                        _d.label = 4;
                    case 4:
                        _d.trys.push([4, 6, , 7]);
                        return [4, this.recursiveRender(currentRenderNode, (0, lodash_1.last)(this.pages))];
                    case 5:
                        _d.sent();
                        return [3, 8];
                    case 6:
                        err_2 = _d.sent();
                        if (err_2 instanceof PageFullError) {
                            this.completePage();
                            this.createPage();
                            currentRenderNode = err_2.continuationRenderNode;
                        }
                        else {
                            console.error("Error encountered while rendering blockId: ".concat(blockId, " (type: ").concat(type, ")"), err_2);
                            throw err_2;
                        }
                        return [3, 7];
                    case 7: return [3, 3];
                    case 8:
                        if (newPagePerBlock || startNewPageAfter)
                            this.completePage();
                        if (startNewPageAfter && block !== (0, lodash_1.last)(this.blockInstances))
                            this.createPage();
                        _d.label = 9;
                    case 9:
                        _b = _a.next();
                        return [3, 2];
                    case 10: return [3, 13];
                    case 11:
                        e_2_1 = _d.sent();
                        e_2 = { error: e_2_1 };
                        return [3, 13];
                    case 12:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_2) throw e_2.error; }
                        return [7];
                    case 13:
                        if (!newPagePerBlock)
                            this.completePage();
                        $("slot").remove();
                        $(this.rawBlocks).remove();
                        return [2];
                }
            });
        });
    };
    return Formatter;
}());
exports.default = Formatter;
