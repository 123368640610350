// Generated by CoffeeScript 1.12.7
(function () {
  var KeyboardController;

  module.exports = KeyboardController = (function () {
    KeyboardController.prototype.currentBlock = 0;

    function KeyboardController($document) {
      $(document).keydown(
        (function (_this) {
          return function (event) {
            var blocks, ref, ref1, ref2;
            if (
              (ref = event.target.tagName) !== "INPUT" &&
              ref !== "TEXTAREA" &&
              (ref1 = (ref2 = event.target.getAttribute("role")) != null ? ref2.toLowerCase() : void 0) !==
                "checkbox" &&
              ref1 !== "radio" &&
              event.which === 32
            ) {
              blocks = $(".project-block");
              _this.currentBlock++;
              if (_this.currentBlock === blocks.length) {
                _this.currentBlock = 0;
              }
              $document.scrollToElement(blocks.eq(_this.currentBlock), 0, 400);
              return event.preventDefault();
            }
          };
        })(this),
      );
    }

    return KeyboardController;
  })();
}.call(this));
