// Generated by CoffeeScript 1.12.7
(function () {
  var GoogleAnalyticsTracker;

  module.exports = GoogleAnalyticsTracker = (function () {
    GoogleAnalyticsTracker.prototype.log = null;

    GoogleAnalyticsTracker.prototype.client = null;

    function GoogleAnalyticsTracker($log, noop) {
      var ref, ref1, ref2, ref3;
      this.log = $log;
      if (
        ((ref = window.qSessionTracking) != null ? ((ref1 = ref.visit) != null ? ref1.identity : void 0) : void 0) !=
          null &&
        typeof ga !== "undefined" &&
        ga !== null
      ) {
        ga("set", "userId", window.qSessionTracking.visit.identity);
      }
      if (
        ((ref2 = window.qSessionTracking) != null ? ((ref3 = ref2.visit) != null ? ref3.token : void 0) : void 0) !=
          null &&
        typeof ga !== "undefined" &&
        ga !== null
      ) {
        ga("set", "visitToken", window.qSessionTracking.visit.token);
      }
      this.trackProjectOpen = (function (_this) {
        return function (event) {
          var ref4;
          if (event == null) {
            event = {};
          }
          return _this.track("Project", "open", (ref4 = window.qSessionTracking) != null ? ref4.projectId : void 0);
        };
      })(this);
      this.trackLinkClick = (function (_this) {
        return function (event) {
          var ref4;
          if (event == null) {
            event = {};
          }
          return _this.track("Link", "click", (ref4 = event.link) != null ? ref4.href : void 0);
        };
      })(this);
      this.trackBlockView = noop;
      this.trackHeartbeat = noop;
      this.trackProjectClose = noop;
      this.trackAcceptClick = (function (_this) {
        return function (event) {
          var ref4;
          if (event == null) {
            event = {};
          }
          return _this.track("Accept button", "click", (ref4 = event.block) != null ? ref4.id : void 0);
        };
      })(this);
      this.trackAccept = (function (_this) {
        return function (event) {
          var ref4;
          if (event == null) {
            event = {};
          }
          return _this.track("Accept button", "accept", (ref4 = event.block) != null ? ref4.id : void 0);
        };
      })(this);
      this.track = (function (_this) {
        return function (eventCategory, eventAction, eventLabel, eventValue) {
          if (typeof ga !== "undefined" && ga !== null) {
            return ga("gaIntegrationTracker.send", "event", {
              eventCategory: eventCategory,
              eventAction: eventAction,
              eventLabel: eventLabel,
              eventValue: eventValue,
            });
          }
        };
      })(this);
    }

    return GoogleAnalyticsTracker;
  })();
}.call(this));
