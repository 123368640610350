// Generated by CoffeeScript 1.12.7
(function () {
  var LogoColor, ModestBadgeController;

  LogoColor = require("Common/Utils/ImageImports").LogoColor;

  module.exports = ModestBadgeController = (function () {
    function ModestBadgeController(createUi, imageLocation, badgeClickUrl) {
      this.ui = createUi(require("./ModestBadge.html"), {
        url:
          "//" +
          badgeClickUrl +
          "/?utm_source=qwilr-branding&utm_medium=qwilr-footer-green&utm_campaign=create-your-own-documents-as-beautiful-mobile-friendly-websites",
        logoColor: LogoColor,
      });
    }

    return ModestBadgeController;
  })();
}.call(this));
