"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerV2PageNavigationClickListener = void 0;
var Util_1 = require("../../Util");
var PollingElementSelector_1 = require("../Utils/PollingElementSelector");
var Event_1 = require("./Event");
var SentryAnalyticsError_1 = require("../Utils/SentryAnalyticsError");
var navButtonSelector = 'nav[data-qwilr-component="navigation-link-wrapper"] button';
var registerV2PageNavigationClickListener = function (eventSender) { return __awaiter(void 0, void 0, Promise, function () {
    return __generator(this, function (_a) {
        $("div.qwilr-pages-app").on("click", navButtonSelector, function (event) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
            return [2, navItemClickHandler(event, eventSender)];
        }); }); });
        return [2];
    });
}); };
exports.registerV2PageNavigationClickListener = registerV2PageNavigationClickListener;
var navItemClickHandler = function (event, eventSender) { return __awaiter(void 0, void 0, void 0, function () {
    var button, blockId, pageInfo, block, _a, blockType, blockName, pageNavClickEvent, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                button = event.target.tagName === "BUTTON" ? $(event.target) : $(event.target).parents("button");
                blockId = button.attr("data-block-id");
                if (!blockId)
                    return [2];
                pageInfo = (0, Util_1.getPageTrackingInfo)(window);
                if (!pageInfo || !blockId) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing pageInfo or blockId in foldableBlock click monitoring"),
                        source: "PageNav",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                        },
                    });
                    return [2];
                }
                return [4, (0, PollingElementSelector_1.pollingElementSelector)({
                        selector: "section[data-qwilr-component=\"block\"][block-id=\"".concat(blockId, "\"]"),
                    })];
            case 1:
                block = _b.sent();
                if (!block) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing block on page for given blockId in page navigation click monitoring"),
                        source: "PageNav",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                        },
                    });
                    return [2];
                }
                _a = (0, Util_1.blockDetailsForBlockElement)(block), blockType = _a.blockType, blockName = _a.blockName;
                if (!blockType || !blockName) {
                    (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                        error: new Error("Missing blockType/blockName in page navigation click monitoring"),
                        source: "PageNav",
                        data: {
                            pageInfo: pageInfo,
                            blockId: blockId,
                            blockType: blockType,
                            blockName: blockName,
                        },
                    });
                    return [2];
                }
                pageNavClickEvent = (0, Event_1.formatPageNavigationClickEvent)({
                    pageInfo: pageInfo,
                    blockId: blockId,
                    blockName: blockName,
                    blockType: blockType,
                });
                return [4, eventSender.send([pageNavClickEvent])];
            case 2:
                _b.sent();
                return [3, 4];
            case 3:
                error_1 = _b.sent();
                (0, SentryAnalyticsError_1.sentryAnalyticsError)({
                    error: error_1,
                    source: "PageNav",
                    description: "PageNav interaction caught in try/catch",
                });
                return [3, 4];
            case 4: return [2];
        }
    });
}); };
