"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PreviewConfig_1 = require("@CommonFrontendBackend/PdfPreviewConfig/PreviewConfig");
var PREVIEW_PERCENTAGE = 0.8;
var addPageDividerIfNotExists = function () {
    if (!$(".page-divider")[0]) {
        var pageDivider = "<div class='page-divider'></div>";
        $(pageDivider).insertAfter(".print-virtual-page");
    }
};
var scaleIframeContent = function (previewWidth, scaleRatio) {
    var previewContent = $(".page-content-wrapper");
    previewContent.css("width", previewWidth);
    previewContent.css("--page-content-width", "".concat(previewWidth, "px"));
    previewContent.css("transform-origin", "top left");
    previewContent.css("transform", "scale(".concat(scaleRatio, ")"));
    previewContent.addClass("pdf-preview");
    var labelScaleRatio = 1 / scaleRatio;
    $(".pdf-preview__iframe-label").css("transform", "scale(".concat(labelScaleRatio, ")"));
};
var addPreviewLabelIfNotExists = function () {
    if (!$(".pdf-preview__iframe-label")[0]) {
        var previewLabel = '<div class="pdf-preview__iframe-label">This is a preview of your download</div>';
        $(previewLabel).insertBefore(".rendered-content");
    }
};
var formatPreview = function (clientUrl, previewWidth, windowWidth, usingPdfBeta) {
    if (windowWidth) {
        var scaleRatio_1 = windowWidth / (previewWidth / PREVIEW_PERCENTAGE);
        addPageDividerIfNotExists();
        if (!usingPdfBeta) {
            addPreviewLabelIfNotExists();
        }
        scaleIframeContent(previewWidth, scaleRatio_1);
        setTimeout(function () {
            var contentHeight = $(".page-content-wrapper.pdf-preview").height();
            window.parent.postMessage({
                status: PreviewConfig_1.PDF_PREVIEW_DONE,
                contentHeight: contentHeight * scaleRatio_1,
            }, clientUrl);
        });
    }
};
var formatPdfPreviewAfterPrintFormatter = function (clientUrl, previewWidth, usingPdfBeta) {
    var handleResize = function () {
        formatPreview(clientUrl, previewWidth, $(window).width(), usingPdfBeta);
    };
    window.addEventListener("resize", handleResize);
    var formatAfterPrint = setInterval(function () {
        if ($("body").attr("loaded-for-pdf")) {
            formatPreview(clientUrl, previewWidth, $(window).width(), usingPdfBeta);
            clearInterval(formatAfterPrint);
        }
    }, 200);
};
exports.default = formatPdfPreviewAfterPrintFormatter;
