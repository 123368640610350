// Generated by CoffeeScript 1.12.7
(function () {
  var AcceptButton;

  module.exports = AcceptButton = (function () {
    function AcceptButton(
      createUi,
      make,
      block,
      config,
      isMobile,
      $timeout,
      manualTooltip,
      appEvents,
      isAcceptable,
      hasAcceptButton,
      di,
    ) {
      if (!config.text) {
        config.text = "Accept";
      }
      this.ui = createUi(require("Quote/Button/Button.html"), {
        viewState: {
          accepted: false,
          formOpen: false,
        },
        hasAcceptButton: hasAcceptButton,
        isAcceptable: isAcceptable,
        text: config.withCustomButton ? config.text : "Accept Quote",
        showCloseButton: (function (_this) {
          return function () {
            return (
              _this.ui.accepter != null && !_this.ui.accepter.viewState.sending && !_this.ui.accepter.viewState.sent
            );
          };
        })(this),
        onClick: (function (_this) {
          return function () {
            var acceptElement, accepter, disabledTooltipConfig, message;
            if (di.has("projectViewMode")) {
              return;
            }
            if (!isAcceptable || _this.ui.viewState.accepted) {
              if (block.$projectStatus === "accepted") {
                message = "This project is already Accepted and cannot be accepted again";
              } else {
                message = "Draft projects can't be Accepted. To enable Accept, set this project live in the Editor";
              }
              disabledTooltipConfig = {
                title: message,
                trigger: "manual",
                container: $(".rendered-content"),
                placement: "top",
                autoClose: true,
              };
              acceptElement = 'section[block-id="' + block._id + '"] div[ui="ui.acceptButton"]';
              manualTooltip(message, acceptElement, disabledTooltipConfig);
              return;
            }
            appEvents.emit("acceptClicked", {
              quoteBlockId: block._id,
            });
            accepter = make(require("Accept/Accept"), {
              quoteBlockId: block._id,
              config: config,
            });
            if (isMobile()) {
              _this.ui.accepter = accepter.ui;
              _this.ui.viewState.formOpen = true;
              $timeout(function () {
                return $("html, body").animate(
                  {
                    scrollTop: $(".accept-form-container").offset().top,
                  },
                  450,
                );
              });
            }
            return accepter.show();
          };
        })(this),
      });
      appEvents.on(
        "accepted",
        (function (_this) {
          return function () {
            return (_this.ui.viewState.accepted = true);
          };
        })(this),
      );
    }

    return AcceptButton;
  })();
}.call(this));
