// Generated by CoffeeScript 1.12.7

/*
  Olark - live chat solution,
  An Olark chat operator wants to know which qwilr a visitor
  is coming from, so they have the best context for serving that visitor.
  This module leverages the Olark API to provide that data.
 */

(function () {
  module.exports = function () {
    return olark("api.chat.onBeginConversation", function () {
      olark("api.chat.sendNotificationToOperator", {
        body: "Looking at qwilr: " + document.title,
      });
      return olark("api.chat.updateVisitorStatus", {
        snippet: "Looking at qwilr: " + document.title,
      });
    });
  };
}.call(this));
