"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatButtonClickedEvent = void 0;
var Types_1 = require("../../Types");
var Types_2 = require("../Types");
var HashWidgetId_1 = require("../Utils/HashWidgetId");
var formatButtonClickedEvent = function (_a) {
    var pageInfo = _a.pageInfo, blockId = _a.blockId, buttonText = _a.buttonText, blockType = _a.blockType, blockName = _a.blockName, buttonElemIndex = _a.buttonElemIndex, buttonLink = _a.buttonLink;
    var interactionName = Types_2.PageInteractionName.ButtonClicked;
    return {
        eventType: Types_1.EventType.PageInteraction,
        eventData: {
            blockId: blockId,
            viewId: pageInfo.pageViewId,
            pageId: pageInfo.pageId,
            accountId: pageInfo.accountId,
            fullName: pageInfo.fullName,
            viewUserId: pageInfo.viewUserId,
            interactionContentIdentifier: buttonText,
            widgetId: (0, HashWidgetId_1.hashWidgetId)({
                interactionName: interactionName,
                blockId: blockId,
                interactionContentIdentifier: "".concat(buttonText).concat(buttonLink).concat(buttonElemIndex),
            }),
            interactionType: "content",
            interactionMetadata: {
                interactionName: interactionName,
                buttonText: buttonText,
                blockType: blockType,
                blockName: blockName,
                buttonLink: buttonLink,
            },
        },
        timestamp: new Date().toISOString(),
    };
};
exports.formatButtonClickedEvent = formatButtonClickedEvent;
